@tailwind base;
@tailwind components;
@tailwind utilities;

/* 🌎 Global Styles */
@layer base {

  html,
  body {
    font-family: 'Poppins', sans-serif;
    }
    
    /* Light Mode Default Variables */
    :root {
      --color-primary: black;
      --color-secondary: #e07d20;
      --color-background: #ffffff;
      --color-text: #282828;
      --color-border: #cfccca;
      --nav-size: 0px;
    }
    
    /* Dark Mode Variables */
    .dark {
      --color-primary: #ffffff;
      --color-secondary: #f0f56c;
      --color-background: black;
      --color-text: #ffffff;
      --color-border: #282828;
      --nav-size: 0px;
    }
    
    /* Apply Colors Globally */
    body {
      @apply transition-colors duration-300;
      background-color: var(--color-background);
      color: var(--color-text);
    }
    
    h1 {
      color: var(--color-primary);
      @apply text-4xl font-bold;
    }

    h2 {
      color: var(--color-secondary);
      @apply text-2xl font-semibold;
    }

    p {
      @apply text-base text-gray-700 dark:text-gray-300;
    }
    }