@tailwind base;
@tailwind components;
@tailwind utilities;

/* 🌎 Global Styles */
@layer base {
    html,
    body {
      font-family: 'Poppins', sans-serif;
      scroll-behavior: smooth;
    }
      
    /* Light Mode Default Variables - Refinados */
    :root {
      --color-primary: #121212;
      --color-secondary: #FFA500;
      --color-background: #f8f8f8;
      --color-text: #222222;
      --color-text-secondary: #555555;
      --color-accent: #FFA500;
      --color-border: #e0e0e0;
      --color-card-bg: #ffffff;
      --color-card-hover: #f2f2f2;
      --nav-size: 0px;
      --transition-fast: 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
      --transition-medium: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
      --transition-slow: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
      --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.05);
      --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.08);
      --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
      --doc-bg-primary: #f8f8f8;
      --doc-bg-secondary: #ffffff;
      --doc-bg-tertiary: #f2f2f2;
      --doc-text-primary: #121212;
      --doc-text-secondary: #666666;
      --doc-border: #e0e0e0;
      --doc-hover: #f5f5f5;
    }
  
    /* Dark Mode Variables - Refinados */
    .dark {
      --color-primary: #f8f8f8;
      --color-secondary: #FFA500;
      --color-background: #0a0a0a;
      --color-text: #f0f0f0;
      --color-text-secondary: #b0b0b0;
      --color-accent: #FFA500;
      --color-border: #333333;
      --color-card-bg: #1c1c1c;
      --color-card-hover: #2c2c2c;
      --nav-size: 0px;
      --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.2);
      --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.3);
      --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.4);
      --doc-bg-primary: #1a1a1a;
      --doc-bg-secondary: #2a2a2a;
      --doc-bg-tertiary: #333333;
      --doc-text-primary: #f0f0f0;
      --doc-text-secondary: #a0a0a0;
      --doc-border: #333333;
      --doc-hover: #2c2c2c;
    }
  }
@layer components {
  /* Contenedor de tarjetas de integración */
  .integration-cards-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.5rem;
    margin-top: 1.5rem;
  }

  /* Tarjeta de integración */
  .integration-card {
    background-color: var(--doc-bg-secondary);
    border-radius: 12px;
    border: 1px solid var(--doc-border);
    overflow: hidden;
    transition: all var(--transition-fast);
    box-shadow: var(--shadow-sm);
  }

  .integration-card:hover {
    transform: translateY(-4px);
    box-shadow: var(--shadow-md);
  }

  .integration-card-header {
    padding: 1.25rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    border-bottom: 1px solid var(--doc-border);
  }

  .integration-card-body {
    padding: 1.25rem;
  }

  .integration-logo {
    width: 40px;
    height: 40px;
    object-fit: contain;
  }

  .integration-logo-small {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }

  .integration-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--doc-text-primary);
    margin: 0;
  }

  .integration-description {
    color: var(--doc-text-secondary);
    margin-bottom: 1.25rem;
    font-size: 0.875rem;
  }

  .integration-button {
    background-color: var(--button-primary-bg) !important;
    color: var(--button-primary-text) !important;
    border: none !important;
    border-radius: 8px !important;
    padding: 0.5rem 1rem !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    transition: all var(--transition-fast) !important;
  }

  .integration-button:hover {
    transform: var(--button-hover-transform);
    opacity: 0.9;
    box-shadow: var(--shadow-sm);
  }

  .integration-button:active {
    transform: var(--button-active-transform);
  }

  /* Sección de cuentas conectadas */
  .integration-section-title {
    font-size: 1.125rem;
    font-weight: 600;
    color: var(--doc-text-primary);
    margin-bottom: 1rem;
  }

  /* Tabla de integraciones */
  .integration-table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    background-color: var(--doc-bg-secondary);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: var(--shadow-sm);
    border: 1px solid var(--doc-border);
  }

  .integration-table-header {
    background-color: var(--table-header-bg);
    color: var(--table-header-text);
    font-size: 0.875rem;
    font-weight: 500;
  }

  .integration-table-header th {
    padding: 0.75rem 1rem;
    text-align: left;
    border-bottom: 1px solid var(--table-border);
  }

  .integration-table-row {
    color: var(--table-text);
    font-size: 0.875rem;
    transition: background-color var(--transition-fast);
  }

  .integration-table-row:hover {
    background-color: var(--table-row-hover);
  }

  .integration-table-row td {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid var(--table-border);
    vertical-align: middle;
  }

  /* Estado de conexión */
  .connection-status {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.75rem;
    font-weight: 500;
  }

  .connection-status.active {
    background-color: rgba(46, 204, 113, 0.15);
    color: #2ecc71;
  }

  .connection-status.expired {
    background-color: rgba(231, 76, 60, 0.15);
    color: #e74c3c;
    cursor: pointer;
  }

  .connection-status.expired:hover {
    background-color: rgba(231, 76, 60, 0.25);
  }

  /* Botón de eliminar conexión */
  .delete-connection-button {
    background: none;
    border: none;
    color: var(--doc-text-secondary);
    cursor: pointer;
    transition: color var(--transition-fast);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 4px;
  }

  .delete-connection-button:hover {
    color: #e74c3c;
    background-color: rgba(231, 76, 60, 0.1);
  }

  .delete-connection-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  /* Mensaje de no conexiones */
  .no-connections-message {
    text-align: center;
    padding: 2rem;
    color: var(--doc-text-secondary);
    background-color: var(--doc-bg-secondary);
    border-radius: 8px;
    border: 1px solid var(--doc-border);
  }

  /* Spinner */
  .button-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .button-spinner {
    color: var(--doc-text-secondary) !important;
    width: 1rem !important;
    height: 1rem !important;
  }

  .dark .button-spinner {
    color: var(--doc-text-primary) !important;
  }
  
.business-viewpanel-subtitle {
    color: var(--doc-text-primary);
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: left;
    font-family: "Poppins", sans-serif;
  }
  .business-viewpanel-title {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
    font-family: "Poppins", sans-serif;
    color: var(--doc-text-primary);
  }
  
  .business-integrations-integration-container {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 16px;
    border: 1px solid var(--doc-border);
    background: var(--doc-bg-secondary);
    transition: all var(--transition-fast);
    box-shadow: var(--shadow-sm);
    position: relative;
    overflow: hidden;
  }
.business-integrations-integration-title {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
    text-align: left;
    font-family: "Poppins", sans-serif;
    color: var(--doc-text-primary);
  }
}
