.business-login-mantis-logo {
  width: 350px;
  text-align: left;
  //margin-top: 300px;
}

.business-login-container {
  justify-content: center;
  align-items: center;
  background-color: var(--doc-bg-secondary);
  box-shadow: var(--shadow-md);
  border-radius: 10px;
  margin-top: 200px;
  border: 1px solid var(--doc-border);
}

.business-login-title {
  font-size: 2.2rem;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: var(--doc-text-primary);
}

.business-login-input {
  box-shadow: var(--shadow-sm);
  background-color: var(--doc-bg-tertiary);
  border-radius: 10px;
  width: 100%;
  height: 50px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  color: var(--doc-text-primary);
  border: 1px solid var(--doc-border);
  transition: all var(--transition-fast);
  
  &:focus {
    border-color: var(--color-accent);
    box-shadow: 0 0 0 3px rgba(var(--color-accent-rgb), 0.2);
  }
}

.business-login-button {
  width: 200px;
  height: 40px;
  background-color: var(--color-accent);
  color: var(--doc-text-primary);
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  border-color: var(--color-accent);
  font-family: "Poppins", sans-serif;
  transition: all var(--transition-fast);
  
  &:hover {
    opacity: 0.9;
    transform: translateY(-2px);
  }
}

.business-login-signup-link {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: var(--doc-text-primary);
  cursor: pointer;
  text-decoration: underline;
  font-family: "Poppins", sans-serif;
  transition: color var(--transition-fast);
  
  &:hover {
    color: var(--color-accent);
  }
}

.business-login-toast {
  background-color: var(--doc-bg-secondary);
  color: var(--doc-text-primary);
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  font-family: "Poppins", sans-serif;
  position: fixed;
  top: 20px;
  right: 450px;
  z-index: 1000;
  opacity: 0;
  transition: opacity 0.5s ease;
  border: 1px solid var(--doc-border);
  box-shadow: var(--shadow-md);
}

.business-login-toast.visible {
  opacity: 1;
}

.business-login-password-container {
  position: relative;
  width: 100%;
}

.business-login-password-toggle {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: var(--doc-text-secondary);
  
  &:hover {
    color: var(--color-accent);
  }
}

.business-login-forgot-password {
  font-size: 14px;
  font-weight: 400;
  text-align: right;
  color: var(--doc-text-secondary);
  cursor: pointer;
  margin-top: 8px;
  font-family: "Poppins", sans-serif;
  
  &:hover {
    color: var(--color-accent);
    text-decoration: underline;
  }
}
