@tailwind base;
@tailwind components;
@tailwind utilities;

/* 🌎 Global Styles */
@layer base {
  html,
  body {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
  }
    
  /* Light Mode Default Variables - Refinados */
  :root {
    --color-primary: #121212;
    --color-secondary: #e9e02c;
    --color-background: #f8f8f8;
    --color-text: #222222;
    --color-text-secondary: #555555;
    --color-accent: #f0f56c;
    --color-border: #e0e0e0;
    --color-card-bg: #ffffff;
    --color-card-hover: #f2f2f2;
    --nav-size: 0px;
    --transition-fast: 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    --transition-medium: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    --transition-slow: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.08);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    --doc-bg-primary: #f8f8f8;
    --doc-bg-secondary: #ffffff;
    --doc-bg-tertiary: #f2f2f2;
    --doc-text-primary: #121212;
    --doc-text-secondary: #666666;
    --doc-border: #e0e0e0;
    --doc-hover: #f5f5f5;
  }

  /* Dark Mode Variables - Refinados */
  .dark {
    --color-primary: #f8f8f8;
    --color-secondary: #f0f56c;
    --color-background: #121212;
    --color-text: #f0f0f0;
    --color-text-secondary: #b0b0b0;
    --color-accent: #f0f56c;
    --color-border: #333333;
    --color-card-bg: #1c1c1c;
    --color-card-hover: #2c2c2c;
    --nav-size: 0px;
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.2);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.3);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.4);
    --doc-bg-primary: #121212;
    --doc-bg-secondary: #1e1e1e;
    --doc-bg-tertiary: #252525;
    --doc-text-primary: #f0f0f0;
    --doc-text-secondary: #a0a0a0;
    --doc-border: #333333;
    --doc-hover: #2c2c2c;
    --doc-icon-brightness: 1.5;
  }
}

/* Componentes del Chat */
@layer components {
  .assistant-chat-container {
    @apply w-full h-[calc(100vh-80px)] relative flex flex-col;
    padding-top: 20px;
    margin: 20px auto 0;
    padding: 0 1rem;
  }

  /* Contenedor de mensajes */
  .assistant-message-wrapper {
    @apply w-full flex-1 flex flex-col gap-3 overflow-y-auto pb-28;
    scroll-behavior: smooth;
    padding-top: 1rem;
  }

  /* Avatar de Mantis */
  .assistant-mantis-avatar {
    @apply absolute -left-10 bottom-0 w-8 h-8 rounded-full flex items-center justify-center;
    background: var(--color-accent);
    border: 2px solid var(--doc-bg-primary);
    box-shadow: var(--shadow-sm);
  }

  /* Contenido del mensaje */
  .assistant-message-content {
    @apply text-base leading-relaxed;
    color: var(--doc-text-primary);
  }

  /* Contenido del mensaje del usuario */
  .assistant-user-message-content {
    @apply text-base leading-relaxed;
    color: var(--color-primary);
  }

  /* Contenedor del input de chat */
  .chat-input-container {
    @apply w-full relative bottom-0 left-0 right-0 z-10;
    margin: 0 auto;    
    max-width: 100%;

  }

  /* Wrapper del input */
  .chat-input-wrapper {
    @apply w-full flex items-center gap-3;
    position: relative;
  }

  /* Campo de entrada mejorado */
  .chat-input {
    @apply w-full p-3 rounded-xl;
    background: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
    color: var(--doc-text-primary);
    min-height: 50px;
    transition: all var(--transition-fast);
    font-size: 0.95rem;
  }

  .chat-input:focus {
    @apply outline-none;
    border-color: var(--color-accent);
    box-shadow: 0 0 0 2px rgba(240, 245, 108, 0.2);
  }

  /* Grupo de botones */
  .chat-button-group {
    @apply flex items-center gap-2;
  }

  /* Botones de chat mejorados */
  .chat-button {
    @apply rounded-xl flex items-center justify-center;
    min-width: 50px;
    min-height: 50px;
    background: var(--color-accent);
    border: none;
    color: var(--color-primary);
    transition: all var(--transition-fast);
    box-shadow: var(--shadow-sm);
  }

  .chat-button:hover {
    opacity: 0.9;
    transform: translateY(-2px);
    box-shadow: var(--shadow-md);
  }

  .chat-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }

  /* Botón de micrófono */
  .chat-button.mic-button {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
  }

  /* Botón de parar grabación */
  .chat-button.stop-button {
    background-color: #ff4d4f;
    color: white;
  }

  /* Iconos de Lucide */
  .chat-button-icon {
    width: 20px;
    height: 20px;
    stroke-width: 2px;
  }

  /* Spinner de carga */
  .chat-loading-spinner {
    @apply w-6 h-6 rounded-full;
    border: 2px solid rgba(240, 245, 108, 0.3);
    border-top-color: var(--color-accent);
    animation: spin 1s ease-in-out infinite;
  }

  /* Sin resultados */
  .no-results-message {
    @apply rounded-2xl p-4 mb-2 transition-all duration-300 w-full text-center;
    max-width: min(90%, 650px);
    background: var(--doc-bg-tertiary);
    border: 1px solid var(--doc-border);
    box-shadow: var(--shadow-sm);
    color: var(--doc-text-secondary);
    animation: fadeIn 0.3s ease-out forwards;
  }

  /* Estilos para el panel de ayuda y el historial de chat */
  .assistant-sidebar-container {
    height: 100%;
    background-color: var(--doc-bg-secondary);
    position: fixed;
    overflow: auto;
    right: 0;
    top: 0;
    z-index: 20;
    border-left: 1px solid var(--doc-border);
    padding: 1rem;
    box-shadow: var(--shadow-md);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* Aseguramos que el contenedor solo se muestre en la sección del asistente */
  body:not(.assistant-section) .assistant-sidebar-container {
    display: none !important;
  }

  /* Añadimos una clase para cuando el panel está colapsado */
  .assistant-sidebar-container.collapsed {
    width: 50px !important;
    padding: 0.5rem;
  }

  /* Mejoramos la transición */
  .assistant-sidebar-container {
    transition: width var(--transition-medium), padding var(--transition-medium);
  }

  .assistant-sidebar-title {
    color: var(--doc-text-primary);
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    padding: 0 0.5rem;
  }

  .assistant-sidebar-subtitle {
    color: var(--doc-text-secondary);
    font-size: 0.875rem;
    margin-bottom: 1.5rem;
    padding: 0 0.5rem;
  }

  .assistant-sidebar-functionalities-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .assistant-sidebar-functionality {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: var(--doc-bg-tertiary);
    cursor: pointer;
    transition: background-color var(--transition-fast);
  }

  .assistant-sidebar-functionality:hover {
    background-color: var(--doc-hover);
  }

  .assistant-sidebar-functionality-title {
    color: var(--doc-text-primary);
    font-size: 0.875rem;
    font-weight: 500;
    margin: 0;
  }

  .assistant-sidebar-functionality-description-container {
    padding: 0.75rem;
    background-color: var(--doc-bg-tertiary);
    border-radius: 0 0 0.5rem 0.5rem;
    margin-top: -0.5rem;
  }

  .assistant-sidebar-functionality-description {
    color: var(--doc-text-secondary);
    font-size: 0.75rem;
    font-weight: 400;
    margin: 0;
  }

  .assistant-sidebar-functionality-ruler {
    border: none;
    height: 1px;
    background-color: var(--doc-border);
    margin: 0.5rem 0;
  }

  /* Estilos para los mensajes en el historial de chat */
  .assistant-user-message {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--doc-text-primary);
    padding: 0.5rem 0.75rem;
    background-color: var(--color-accent);
    border-radius: 0.5rem;
    align-self: flex-end;
    max-width: 80%;
    word-break: break-word;
  }

  .assistant-mantis-message {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--doc-text-primary);
    padding: 0.5rem 0.75rem;
    background-color: var(--doc-bg-tertiary);
    border-radius: 0.5rem;
    align-self: flex-start;
    max-width: 80%;
    word-break: break-word;
  }

  .assistant-link {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    text-decoration: underline;
    color: var(--color-accent);
  }

  .assistant-mantis-message-timestamp {
    font-size: 0.6875rem;
    font-weight: 500;
    color: var(--doc-text-secondary);
    margin-left: 0.5rem;
    margin-top: 0.25rem;
    align-self: flex-start;
  }

  .assistant-user-message-timestamp {
    font-size: 0.6875rem;
    font-weight: 500;
    color: var(--doc-text-secondary);
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    align-self: flex-end;
    text-align: right;
  }

  .conversation-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
  }

  .conversation-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    border-radius: 0.5rem;
    background-color: var(--doc-bg-tertiary);
    cursor: pointer;
    transition: background-color var(--transition-fast);
  }

  .conversation-date:hover {
    background-color: var(--doc-hover);
  }

  .conversation-messages {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    background-color: var(--doc-bg-tertiary);
    border-radius: 0.5rem;
    margin-top: -0.25rem;
  }

  /* Animación para los mensajes */
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translateX(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translateX(10px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }

  /* Estilos para los botones de flecha en el panel de ayuda */
  .help-panel-arrow-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 20px;
    width: 100%;
    padding: 10px;
    cursor: pointer;
    transition: all var(--transition-fast);
    background-color: var(--doc-bg-tertiary);
    border-radius: 8px;
    margin-left: 5px;
    margin-right: 5px;
    min-width: 20px;
    z-index: 30;
  }

  .help-panel-arrow-container:hover {
    background-color: var(--doc-hover);
  }

  .help-panel-arrow-left, 
  .help-panel-arrow-right {
    width: 32px !important;
    height: 32px !important;
    color: var(--doc-text-primary);
    stroke-width: 2px;
    min-width: 32px !important;
    min-height: 32px !important;
  }

  /* Estilo específico para el panel colapsado */
  .assistant-sidebar-container:not([style*="width: 300px"]) .help-panel-arrow-container {
    margin-left: 0;
    margin-right: 0;
    width: 0px;
    height: 0px;
    padding: 1px;
  }

  /* Mejoras en los contenedores de mensajes */
  .assistant-mantis-message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
    margin-bottom: 1rem;
    position: relative;
    padding-left: 2.5rem;
    animation: fadeInLeft 0.3s ease-out;
  }

  .assistant-user-message-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
    max-width: 100%;
    margin-bottom: 1rem;
    animation: fadeInRight 0.3s ease-out;
  }

  .assistant-mantis-avatar {
    position: absolute;
    left: 0;
    top: 0;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: var(--color-accent);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: var(--doc-bg-primary);
    font-size: 1rem;
  }

  .assistant-user-message {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--doc-text-primary);
    padding: 0.75rem 1rem;
    background-color: var(--color-accent);
    color: var(--doc-bg-primary);
    border-radius: 1rem 1rem 0 1rem;
    align-self: flex-end;
    max-width: 100%;
    word-break: break-word;
  }

  .assistant-mantis-message {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--doc-text-primary);
    padding: 0.75rem 1rem;
    background-color: var(--doc-bg-tertiary);
    border-radius: 0 1rem 1rem 1rem;
    align-self: flex-start;
    max-width: 100%;
    word-break: break-word;
    box-shadow: var(--shadow-sm);
  }

  .assistant-mantis-message-timestamp {
    font-size: 0.6875rem;
    font-weight: 500;
    color: var(--doc-text-secondary);
    margin-left: 0.75rem;
    margin-top: 0.25rem;
    align-self: flex-start;
  }

  .assistant-user-message-timestamp {
    font-size: 0.6875rem;
    font-weight: 500;
    color: var(--doc-text-secondary);
    margin-right: 0.75rem;
    margin-top: 0.25rem;
    align-self: flex-end;
    text-align: right;
  }

  /* Mejoras en el historial de conversaciones */
  .conversation-container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border-radius: 0.75rem;
    background-color: var(--doc-bg-secondary);
    box-shadow: var(--shadow-sm);
  }

  .conversation-date {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;
    background-color: var(--doc-bg-tertiary);
    cursor: pointer;
    transition: background-color var(--transition-fast);
    font-weight: 500;
  }

  .conversation-date:hover {
    background-color: var(--doc-hover);
  }

  .conversation-messages {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.75rem;
    background-color: var(--doc-bg-tertiary);
    border-radius: 0.5rem;
    margin-top: 0.25rem;
    border: 1px solid var(--doc-border);
  }


  .assistant-sidebar-title {
    color: var(--doc-text-primary);
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1rem;
    text-align: center;
  }

  /* Spinner mejorado */
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid var(--doc-bg-tertiary);
    border-radius: 50%;
    border-top-color: var(--color-accent);
    animation: spinner-rotation 1s ease-in-out infinite;
    margin: 2rem auto;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 200px;
  }

  /* Indicador de "escribiendo..." */
  .assistant-typing-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    background-color: var(--doc-bg-tertiary);
    border-radius: 12px;
    width: fit-content;
    margin-bottom: 0.5rem;
  }

  .typing-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin: 0 2px;
    background-color: var(--doc-text-secondary);
    border-radius: 50%;
    opacity: 0.6;
    animation: typing-animation 1.4s infinite ease-in-out both;
  }

  .typing-dot:nth-child(1) {
    animation-delay: 0s;
  }

  .typing-dot:nth-child(2) {
    animation-delay: 0.2s;
  }

  .typing-dot:nth-child(3) {
    animation-delay: 0.4s;
  }

  @keyframes typing-animation {
    0%, 80%, 100% {
      transform: scale(0.6);
      opacity: 0.6;
    }
    40% {
      transform: scale(1);
      opacity: 1;
    }
  }
}

/* Estilos para dispositivos móviles */
@media (max-width: 768px) {
  .assistant-mantis-message-container,
  .assistant-user-message-container {
    max-width: 95%;
  }

  .assistant-mantis-avatar {
    @apply -left-8 w-6 h-6;
  }

  .assistant-chat-container {
    padding: 0 0.5rem;
  }

  .chat-input-wrapper {
    @apply gap-2;
  }

  .chat-button {
    min-width: 40px;
    min-height: 40px;
    padding: 0.5rem;
  }
  
  .assistant-sidebar-container {
    width: 100%;
    max-width: 100%;
  }
}