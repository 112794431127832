.home-component {
  position: relative;

  .app-content {
    position: absolute;
    left: var(--nav-size);
    overflow-y: auto;
    width: calc(100vw - #{var(--nav-size)});
    overflow-x: hidden;
  } 
}

.home-component, .home-component .app-content {
  height: calc(100vh - #{var(--nav-size)});
}


