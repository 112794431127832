@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.animate-scroll {
  animation: scroll 20s linear infinite;
}

.business-backoffice-container {
  width: "100%";
}

.business-backoffice-section-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.business-backoffice-bottom-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.business-backoffice-top-buttons-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}

.business-sidebar-logo {
  width: 60px;
  background-color: #f5f5f5;
  margin-bottom: 20px;
}

.business-name {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 10px;
  text-align: center;
}

.business-sidebar-tab-title {
  font-size: 0.8rem;
  font-weight: 500;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: white;
  height: 32px;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
}



.business-logout-button {
  width: 150px;
  height: 40px;
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  border: 0px;
  border-radius: 10px;
  padding: 5px 0;
  font-family: "Poppins", sans-serif;
}

.business-notification-amount {
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  font-size: 12px;
  font-weight: 600;
  border-radius: 50%;
  text-align: center;
  position: absolute;
  right: 20px;
  top: 10px;
}

.business-backoffice-viewpanel {
  //width: calc(100vw - 200px);
  height: 100vh;
  overflow: auto;
  scrollbar-width: thin;
  float: left;
  padding: 20px;
  //left: 200px;
  position: absolute;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
}

.yellow-blur {
  background-color: rgba(240, 245, 108, 0.15);
  border-radius: 981.099px;
  filter: blur(80px);
  position: fixed;
  width: 1800px;
  height: 981.099px;
  flex-shrink: 0;
  top: -25%;
  right: -40%;
  z-index: 1;
  pointer-events: none;
}

.blue-blur {
  background-color: #a1b8be;
  border-radius: 981.099px;
  filter: blur(62px);
  position: fixed;
  width: 667.354px;
  height: 981.099px;
  flex-shrink: 0;
  bottom: -20%;
  left: 10%;
  z-index: 1;
  opacity: 0.25;
  pointer-events: none;
}



.business-viewpanel-tab-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: black;
}



.employees-list-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border-radius: 10px;
}

.add-employee-button {
  width: 40px;
  height: 40px;
  background-color: var(--color-background);
  color: white;
  font-size: 24px;
  font-weight: 600;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
}

.add-employee-text {
  font-size: 20px;
  font-weight: 500;
  margin-left: 10px;
  font-family: "Poppins", sans-serif;
  color: white;
}

.new-employee-form-label {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  color: rgb(255, 255, 255);
}

.country {
  height: 50px;
  .country-name {
    font-size: 20px;
    margin-left: 10px;
  }
  .dial-code {
    font-size: 20px;
    margin-left: 10px;
  }
}

.create-new-employee-button {
  width: 250px;
  height: 40px;
  background-color: var(--color-background);
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}

.employee-files-profile-picture {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.employee-files-employee-name {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: white;
}


.employee-folder-card {
  width: "100%";
  //height: 110px;
  //background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  /* border-radius: 10px; */
  margin-bottom: 20px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    rgba(240, 245, 108, 0.8) -74.47%,
    rgba(240, 245, 108, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
}

.employee-document-card {
  width: "100%";
  height: 60px;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    rgba(240, 245, 108, 0.8) -74.47%,
    rgba(240, 245, 108, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  color: white;
}

.employee-document-search-card {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(327deg,
      rgba(240, 245, 108, 0.8) -74.47%,
      rgba(240, 245, 108, 0) 64.17%);
  backdrop-filter: blur(22.812965393066406px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  color: white;
}

.archive-search-bar {
  padding: 8px;
  border: 1px solid transparent;
  width: 100%;
  border-radius: 5px;
  /* Use hyphenated property name */
  font-family: "Poppins", sans-serif;
  /* Use hyphenated property name */
  background-color: transparent;
  /* Use hyphenated property name */
  margin-left: 8px;
  color: white;

  &:focus {
    outline: none;
  }
}
.employee-document-icon {
  width: 50px;
  height: 50px;
}

.employee-document-search-icon {
  width: 36px;
  height: 36px;
}
.employee-folder-logo {
  width: 50px;
  height: 50px;
  margin-bottom: 20px;
}

.employee-folder-logo-mini {
  width: 40px;
  height: 40px;
  margin-bottom: 20px;
}

.employee-folder-icon {
  font-size: 30px;
  //margin-bottom: 20px;
}

.employee-folder-name {
  font-size: 16px;
  font-weight: 500;
  //margin-bottom: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: white;
}

.employee-document-name {
  font-size: 16px;
  font-weight: 400;
  margin-top: auto;
  margin-bottom: auto;
  font-family: "Poppins", sans-serif;
  color: white;
}

.employee-add-files-button {
  width: 300px;
  height: 60px;
  border-radius: 300px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    rgba(240, 245, 108, 0.8) -74.47%,
    rgba(255, 0, 0, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
  color: white;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
  position: fixed;
  bottom: 80px;
  right: 70px;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-folder-modal {
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.assign-format-modal {
  //height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-folder-modal-item {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
  justify-content: space-between;
  padding: 10px;
  cursor: pointer;
  font-size: 0.9em;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);

  }
}
.files-to-upload-subtitle {
  font-size: 1.4rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
  margin-top: 20px;
}

.business-notifications-date {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.business-notification-container {
  width: 100%;
  height: 100%;
  padding: 5px;
  margin-top: 10px;
}

.business-notification-subject {
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  font-family: "Poppins", sans-serif;
  align-self: center;
  margin-top: 8px;
}

.business-notification-message {
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.business-notifications-additional-info {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 5px;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.business-integrations-integration-container {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    rgba(240, 245, 108, 0.8) -74.47%,
    rgba(240, 245, 108, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
}

.business-integrations-integration-logo {
  height: 40px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.business-integrations-integration-logo-small {
  height: 30px;
}


.PhoneInputInput {
  width: 100%;
  height: 35px;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  background-color: white;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
}



.assistant-related-document-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 2px;
}

.assistant-related-document-name {
  font-size: 11px;
  font-weight: 500;
  margin-bottom: 5px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: white;
  margin-right: 15px;
}

.assistant-related-document-details {
  font-size: 9px;
  font-weight: 500;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: rgba(255, 255, 255, 0.4);
}

.assistant-related-documents-expand-button {
  width: 100px;
  cursor: pointer;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  color: white;
  margin-left: 20px;
  margin-bottom: 10px;
}

.assistant-related-mail-container {
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 0px;
  background-color: #feffdf;
  padding: 15px;
  padding-left: 10px;
}

.assistant-related-document-email-content {
  font-size: 11px;
  font-weight: 500;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: rgba(0, 0, 0, 0.6);
  white-space: pre-line;
}

.mantis-mission-container {
  width: 100%;
  height: 150px;
  padding: 25px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    #bbffe6 -74.47%,
    rgba(187, 255, 230, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.mantis-new-mission-container {
  width: 100%;
  height: 150px;
  padding: 25px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #ffffff;
  border-radius: 20px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    #bbffe6 -74.47%,
    rgba(187, 255, 230, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.mantis-mission-title {
  font-size: 1.2rem;
  font-weight: 500;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: white;
}

.mantis-mission-description {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: white;
}

.mantis-mission-file-icon {
  width: 30px;
  height: 30px;
}

.mantis-mission-file-name {
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-top: auto;
}

.mantis-mission-file {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  height: 50px;
}

//Business extraction section
.business-extraction-filter-button {
  //display: flex;
  height: 48px;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    rgba(240, 245, 108, 0.8) -74.47%,
    rgba(240, 245, 108, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.business-extraction-table-cell-red {
  //display: flex;
  height: 48px;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    rgba(192, 30, 19, 0.8) -74.47%,
    rgba(240, 245, 108, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}
.business-extraction-table-cell-yellow {
  //display: flex;
  height: 48px;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    rgba(233, 210, 6, 0.8) -74.47%,
    rgba(240, 245, 108, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}
.business-extraction-table-cell-green {
  //display: flex;
  height: 48px;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    rgba(49, 109, 21, 0.8) -74.47%,
    rgba(240, 245, 108, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  padding-top: 2px;
  padding-bottom: 2px;
}

.business-extraction-filter-button-text {
  color: #fff;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-style: normal;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 600;
}

.business-extraction-table-header {
  font-size: 1rem;
  font-weight: 600;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: white;
}

.business-extraction-table-title {
  font-size: 1.5rem;
  font-weight: 600;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: white;
}

.business-extraction-table-row {
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: white;
  cursor: pointer;
}

.business-extraction-file-analysis-modal {
  width: "100vw" !important;
  height: "50%";
  display: flex;
  align-items: center;
  justify-content: center;
  .modal-content {
    background-color: transparent;
    border-color: transparent;
    height: 50%;
  }
  &.modal-dialog {
    max-width: 100vw;
  }
}

.business-extraction-file-analysis-modal-body {
  width: "100%";
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.document-analysis-module-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  margin-top: 10px;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    317deg,
    rgba(240, 245, 108, 0.5) -3.01%,
    rgba(0, 0, 0, 0) 106.92%
  );
  backdrop-filter: blur(22.812965393066406px);
  overflow: scroll;
  scrollbar-width: none;
  .upload-files-button {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background-color: rgba(240, 245, 108, 0.8);
    color: #000;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.2s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: rgba(240, 245, 108, 1);
      transform: translateY(-2px);
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    }

    i {
      font-size: 1.1em;
    }

    span {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.9em;
    }
  }
}

.document-analysis-module-container-alerts {
  width: 100%;
  height: 100%;
  padding: 20px;
  margin-top: 10px;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    315deg,
    #ffb159 -12.79%,
    rgba(0, 0, 0, 0) 106.96%
  );
  backdrop-filter: blur(22.812965393066406px);
  overflow: scroll;
  scrollbar-width: none;
}

.document-analysis-module-alert {
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  font-family: "Poppins", sans-serif;
}

.document-analysis-module-title {
  font-size: 20px;
  font-weight: 600;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: white;
  margin-top: auto;
  margin-bottom: auto;
}

.document-analysis-module-fields-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.document-analysis-module-field {
  color: white;
}

.document-analysis-module-file {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.archive-pagination-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.archive-pagination-page-number-container {
  width: 40px;
  height: 40px;
  background-color: var(--color-background);
  color: white;
  font-size: 24px;
  font-weight: 600;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.archive-pagination-page-number {
  font-size: 1.4rem;
  font-weight: 500;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: white;
}

.archive-pagination-button {
  width: 40px;
  height: 40px;
  background-color: var(--color-background);
  color: white;
  font-size: 24px;
  font-weight: 600;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.document-analysis-module-related-document-container {
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 15px;
  padding-left: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    317deg,
    rgba(240, 245, 108, 0.5) -3.01%,
    rgba(0, 0, 0, 0) 106.92%
  );
}

.document-analysis-module-active-related-document-container {
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 15px;
  padding-left: 10px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    317deg,
    #ffffff -12.79%,
    rgba(0, 0, 0, 0) 106.96%
  );
}

.document-analysis-module-related-document-name {
  font-size: 0.8rem;
  font-weight: 500;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: white;
  white-space: pre-line;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15px;
}

.document-analysis-module-related-document-icon {
  width: 50px;
}

.assistant-message-feedback-container {
  width: fit-content;
  max-width: 70%;
  height: 100%;
  margin-left: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.assistant-message-feedback-button {
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
  &:hover {
    color: #f0f56c;
    font-size: 18px;
    transition: font-size 0.15s;
  }
}

.business-extraction-filter-label {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: white;
}

.business-extraction-filter-input {
  width: 100%;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  background-color: white;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  height: 48px;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    rgba(240, 245, 108, 0.8) -74.47%,
    rgba(240, 245, 108, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  &:focus {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    background-color: white;
    box-shadow: none;
    font-family: "Poppins", sans-serif;
    height: 48px;
    border-radius: 21.292px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: linear-gradient(
      327deg,
      rgba(240, 245, 108, 0.8) -74.47%,
      rgba(240, 245, 108, 0) 64.17%
    );
    backdrop-filter: blur(22.812965393066406px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}

.business-extraction-filter-input-green {
  width: 100%;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  background-color: white;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  height: 48px;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    rgba(49, 109, 21, 0.8) -74.47%,
    rgba(49, 109, 21, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  &:focus {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    background-color: white;
    box-shadow: none;
    font-family: "Poppins", sans-serif;
    height: 48px;
    border-radius: 21.292px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: linear-gradient(
      327deg,
      rgba(49, 109, 21, 0.8) -74.47%,
      rgba(49, 109, 21, 0) 64.17%
    );
    backdrop-filter: blur(22.812965393066406px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}

.business-extraction-filter-input-red {
  width: 100%;
  padding: 10px;
  font-size: 15px;
  border-radius: 5px;
  border: none;
  background-color: white;
  box-shadow: none;
  font-family: "Poppins", sans-serif;
  height: 48px;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    327deg,
    rgba(192, 30, 19, 0.8) -74.47%,
    rgba(192, 30, 19, 0) 64.17%
  );
  backdrop-filter: blur(22.812965393066406px);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  &:focus {
    width: 100%;
    padding: 10px;
    font-size: 15px;
    border-radius: 5px;
    border: none;
    background-color: white;
    box-shadow: none;
    font-family: "Poppins", sans-serif;
    height: 48px;
    border-radius: 21.292px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background: linear-gradient(
      327deg,
      rgba(192, 30, 19, 0.8) -74.47%,
      rgba(192, 30, 19, 0) 64.17%
    );
    backdrop-filter: blur(22.812965393066406px);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}

.business-extraction-select-option {
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: white;
  background-color: var(--color-background);
}

.user-form-section {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  padding: 20px;
  margin-bottom: 20px;

  .section-title {
    color: white;
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: 500;
  }
}

.user-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 20px;

  .action-button {
    background: white;
    color: #444;
    border: none;
    border-radius: 8px;
    padding: 10px 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    transition: all 0.3s ease;
    min-width: 200px;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
      transform: none;
    }
  }
}

// Estilos para las secciones del formulario
.user-form-section {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.section-title {
  color: white;
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 25px;
  font-family: "Poppins", sans-serif;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 10px;
}

.form-label {
  color: white;
  font-size: 14px;
  margin-bottom: 8px;
  font-family: "Poppins", sans-serif;
}

// Estilos para los botones de acción
.action-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 30px;

  .action-button {
    background: white;
    color: #444;
    border: none;
    border-radius: 8px;
    padding: 10px 30px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-2px);
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    }
  }
}

// Estilos para la tabla
.table-actions {
  display: flex;
  gap: 15px;
  justify-content: flex-end;

  i {
    color: white;
    font-size: 16px;
    margin: 0 8px;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      opacity: 0.8;
    }
  }
}

.user-form-section {
  background: rgba(255, 255, 255, 0.05);
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;

  .section-title {
    color: white;
    margin-bottom: 20px;
  }

  .form-label {
    color: white;
  }

  .new-employee-form-input {
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: white;
    border-radius: 8px;
    padding: 10px 15px;
    height: 42px;
    width: 100%;
    margin-bottom: 15px;
    font-family: "Poppins", sans-serif;

    &:focus {
      background-color: rgba(255, 255, 255, 0.12);
      border-color: #f0f56c;
      box-shadow: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .new-employee-form-input-dropdown-option {
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: black;
    font-family: "Poppins", sans-serif;
  }

  .new-employee-form-input-phone {
    background-color: rgba(255, 255, 255, 0.08);
    border: 1px solid rgba(255, 255, 255, 0.15);
    color: black;
    border-radius: 8px;
    padding: 10px 15px;
    height: 42px;
    width: 100%;
    margin-bottom: 15px;
    font-family: "Poppins", sans-serif;

    &:focus {
      background-color: rgba(255, 255, 255, 0.12);
      border-color: #f0f56c;
      box-shadow: none;
    }

    &::placeholder {
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
    margin-top: 20px;

    .action-button {
      min-width: 120px;

      &.submit {
        background-color: #f0f56c;
        color: #333;
      }

      &.cancel {
        background-color: transparent;
        border: 1px solid rgba(255, 255, 255, 0.2);
        color: white;
      }
    }
  }
}

// Añade estos estilos para la tabla de usuarios
.users-list-container {
  margin: 20px;
  overflow-x: auto;
  width: calc(100% - 40px);

  table {
    min-width: 100%;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 20px;

    th {
      color: white;
      padding: 15px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    td {
      color: white;
      padding: 12px 15px;
      font-family: "Poppins", sans-serif;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }

    tbody tr {
      &:hover {
        background: rgba(255, 255, 255, 0.08);
      }
    }
  }

  // Botón de crear usuario
  .action-button {
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 5px 10px;
    border-radius: 4px;
    color: white;
    transition: all 0.3s ease;
    margin-bottom: 20px;
    font-family: "Poppins", sans-serif;

    &:hover {
      background: #f0f56c;
      color: #333;
      border-color: #f0f56c;
    }
  }

  // Botones de acción en la tabla
  .user-actions {
    display: flex;
    gap: 10px;

    .action-button {
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 5px 10px;
      border-radius: 4px;
      color: white;
      transition: all 0.3s ease;
      margin-bottom: 0;

      &.edit:hover {
        background: #f0f56c;
        color: #333;
        border-color: #f0f56c;
      }

      &.delete:hover {
        background: #ff4444;
        color: white;
        border-color: #ff4444;
      }

      i {
        font-size: 14px;
      }
    }
  }
}

.permissions-column {
  max-width: 300px;

  .permissions-cell {
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: white;
    }

    .view-more-btn {
      background: transparent;
      border: 1px solid rgba(255, 255, 255, 0.2);
      color: white;
      padding: 2px 8px;
      font-size: 12px;
      border-radius: 4px;
      white-space: nowrap;
      transition: all 0.3s ease;

      &:hover {
        background: #f0f56c;
        color: #333;
        border-color: #f0f56c;
      }
    }
  }

  .permissions-list {
    max-height: 60px;
    overflow: hidden;
    transition: max-height 0.3s ease;

    &.expanded {
      max-height: 500px;
    }
  }

  .permission-item {
    color: white;
    padding: 4px 0;
    font-family: "Poppins", sans-serif;
  }
}

// Estilos para el toast
.permissions-toast-container {
  background: rgba(0, 0, 0, 0.9) !important;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px !important;
  padding: 15px !important;

  .Toastify__toast {
    background: transparent;
  }
}

.permissions-toast {
  white-space: pre-line;

  strong {
    color: #f0f56c;
    display: block;
    margin-bottom: 12px;
    font-size: 16px;
  }

  .permission-item {
    color: white;
    padding: 6px 0;
    font-family: "Poppins", sans-serif;
    opacity: 0;
    animation: fadeIn 0.3s ease forwards;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(5px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.permissions-cell,
.users-cell {
  position: relative;

  .permissions-list,
  .users-list {
    max-height: 24px;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    &.expanded {
      max-height: 500px;
    }
  }

  .permission-item,
  .user-item {
    padding: 2px 0;
    color: white;
  }

  .expand-button {
    color: #f0f56c;
    cursor: pointer;
    font-size: 0.8rem;
    margin-top: 4px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.table-cell-content {
  display: flex;
  flex-direction: column;

  .main-content {
    color: white;
  }

  .secondary-content {
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8rem;
  }
}

// Estilos para la tabla y contenedor
.users-list-container {
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 20px;
  margin-top: 20px;

  table {
    width: 100%;

    th {
      color: white;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      padding: 15px 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    td {
      color: white;
      font-family: "Poppins", sans-serif;
      padding: 15px 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.05);
    }
  }
}

// Estilos para los botones de acción
.user-actions {
  display: flex;
  gap: 10px;
  justify-content: flex-end;

  .action-button {
    background: none;
    border: none;
    padding: 5px;
    transition: all 0.2s ease;

    &.edit {
      color: #f0f56c;

      &:hover {
        color: lighten(#f0f56c, 10%);
        transform: translateY(-2px);
      }
    }

    &.delete {
      color: #ff4d4d;

      &:hover {
        color: lighten(#ff4d4d, 10%);
        transform: translateY(-2px);
      }
    }

    i {
      font-size: 16px;
    }
  }
}

// Estilos para las celdas expandibles
.permissions-column,
.users-column {
  max-width: 250px;

  .permissions-list,
  .users-list {
    position: relative;

    &.expanded {
      .permission-item,
      .user-item {
        display: block;
        margin-bottom: 4px;
      }
    }
  }
}

// Estilos para el botón de expandir
.expand-button {
  color: #f0f56c;
  font-size: 0.8rem;
  cursor: pointer;
  margin-top: 4px;

  &:hover {
    text-decoration: underline;
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .custom-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    width: 80%;
    max-width: 600px;
    border-radius: 21.292px;
    background: linear-gradient(
      315deg,
      #ffb159 -12.79%,
      rgba(0, 0, 0, 0) 106.96%
    );
    backdrop-filter: blur(22.812965393066406px);
    border: 1px solid rgba(255, 255, 255, 0.3);
    animation: fadeIn 0.3s ease-out;
  }

  .custom-modal-backdrop {
    will-change: opacity;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    animation: fadeIn 0.3s ease-out;
  }

  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: white;
    cursor: pointer;
    z-index: 1001;
  }

  .document-source-dropdown {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 300px;
  }

  .modal-field {
    display: flex; /* Usar flexbox para alinear los elementos */
    align-items: center; /* Alinear verticalmente los elementos */
    margin-bottom: 10px; /* Espacio entre campos */
    flex-wrap: wrap; /* Permitir que los campos se envuelvan */
  }

  .modal-field label {
    width: 150px; /* Ancho fijo para las etiquetas */
    margin-right: 10px; /* Espacio entre la etiqueta y el campo */
    font-weight: bold; /* Texto en negrita para las etiquetas */
  }

  .modal-field select,
  .modal-field input {
    flex: 1; /* Permitir que el campo ocupe el espacio restante */
    padding: 5px; /* Espacio interno para los campos */
    border: 1px solid #ccc; /* Borde para los campos */
    border-radius: 3px; /* Bordes redondeados */
    background-color: #000000; /* Fondo blanco para los campos */
    margin-right: 10px; /* Espacio entre campos en la misma línea */
    min-width: 200px; /* Ancho mínimo para los campos */
  }

  .modal-field:nth-child(odd) {
    margin-right: 0; /* Eliminar margen derecho para el último campo en una fila impar */
  }
}

.business-extraction-modal {
  .modal-content {
    background-color: #2c2c2c;
    border: 1px solid #444;
  }

  .business-extraction-modal-header {
    border-bottom: 1px solid #444;
    padding: 1rem;

    .close {
      color: white;
    }
  }

  .business-extraction-modal-body {
    padding: 1rem;
  }

  .business-extraction-modal-footer {
    border-top: 1px solid #444;
    padding: 1rem;
  }

  .business-extraction-modal-button {
    min-width: 100px;
  }
}

.modal-section {
  margin-bottom: 30px;
  padding: 20px;
  border-radius: 21.292px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(
    317deg,
    rgba(240, 245, 108, 0.5) -3.01%,
    rgba(0, 0, 0, 0) 106.92%
  );
  backdrop-filter: blur(22.812965393066406px);

  h4 {
    color: white;
    font-family: "Poppins", sans-serif;
    margin-bottom: 20px;
  }

  .modal-field {
    margin-bottom: 15px;

    label {
      color: white;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 0.9rem;
    }

    input,
    select {
      background-color: rgba(94, 92, 92, 0.5);
      border: 1px solid rgba(255, 255, 255, 0.3);
      color: white;
      border-radius: 10px;
      padding: 8px 12px;
      font-family: "Poppins", sans-serif;

      &:focus {
        outline: none;
        border-color: rgba(240, 245, 108, 0.8);
      }

      option {
        background-color: #2c2c2c;
        color: white;
      }
    }
  }
}

.custom-modal {
  &.business-extraction-modal {
    max-height: 80vh;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.3);
      border-radius: 4px;
    }
  }
}

.filter-section-container {
  margin-top: 30px;

  .filter-section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    background-color: rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    cursor: pointer;
    margin-bottom: 10px;
    transition: background-color 0.2s ease;
    width: adjust-content;
    height: 40px;

    &:hover {
      background-color: rgba(255, 255, 255, 0.12);
    }

    .filter-section-title {
      margin-left: 10px;
      color: white;
      font-size: 0.8em;
      font-weight: 500;
      font-family: "Poppins", sans-serif;
      margin-top: auto;
    }

    i {
      color: white;
      font-size: 0.9em;
      transition: transform 0.2s ease;
    }
  }

  .collapse {
    transition: height 0.3s ease-in-out;
  }
}

.internal-message-card {
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 16px 24px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: "Poppins", sans-serif;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }

  .internal-message-subject {
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
  }

  .internal-message-preview {
    color: #b8b8b8;
    font-size: 14px;
    margin-bottom: 12px;
    line-height: 1.4;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: left;
  }

  .internal-message-meta {
    color: #808080;
    font-size: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.document-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 30px;
}

.modal-field {
  display: flex;
  flex-direction: column; /* Coloca la etiqueta encima del campo */
  flex: 1 1 200px; /* Permite que los elementos crezcan y se encojan, con un tamaño base de 200px */
  min-width: 150px; /* Ancho mínimo para cada campo */
}

.document-analysis-module-title {
  width: 100%;
  margin-bottom: 10px;
}


.section-divider {
  border-bottom: 1px solid #ccc; /* Línea divisoria */
  margin-bottom: 20px; /* Espacio debajo de cada sección */
  padding-bottom: 20px; /* Espacio interno para la sección */
}

.file-upload-section {
  margin-top: 20px;
}

.file-upload-label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.file-upload-input {
  display: block;
  width: 100%;
}

/* styles/businessBackofficeStyles.scss */
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.ReactModal__Content button {
  font-family: "Poppins", sans-serif;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.ReactModal__Content button:hover {
  background-color: #0056b3;
}

.predicted {
  border: 2px solid yellow;
}

.document-analysis-module-input {
  font-family: "Poppins", sans-serif;
  margin-bottom: 10px;
}

.notification-bell {
  position: fixed;
  top: 20px;
  right: 30px;
  z-index: 1000;
  font-size: 44px;
  cursor: pointer;
}

.notifications-modal {
  position: absolute;
  top: 10px;
  right: 330px;
  transform: translate(-50%, -50%);
  height: 450px;
  width: 1000px;
  background-color: #000000;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  padding: 20px;
  border: 1px solid #444;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  /* Webkit (Safari/Chrome) */
  &::-webkit-scrollbar {
    display: none;
  }
}

.notifications-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.notifications-title {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 20px;
  text-align: left;
  font-family: "Poppins", sans-serif;
  color: rgb(0, 0, 0);
}
/*
.file-preview-modal{
  width: 80%;
  max-width: 600px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
*/

.file-preview-modal {
  width: 90% !important;
  max-width: 1200px !important;
  height: 90vh !important;
  background-color: #1a1a1a !important;
  border-radius: 12px !important;
  padding: 30px !important;
  overflow-y: auto !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;

  .file-preview {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow: scroll;

    .file-item {
      width: 100%;

      h2 {
        color: white;
        font-size: 1.5rem;
        margin-bottom: 15px;
        font-family: "Poppins", sans-serif;
      }

      .preview-container {
        width: 100%;
        height: 70vh;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        padding: 10px;

        iframe,
        img {
          width: 100% !important;
          height: 100% !important;
          border-radius: 8px !important;
          border: 1px solid rgba(255, 255, 255, 0.1) !important;
        }
      }
    }
  }

  .modal-actions {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 15px;
    margin-top: 20px;

    button {
      min-width: 120px;
      padding: 10px 20px;
      border-radius: 6px;
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      transition: all 0.3s ease;

      &.cancel-button {
        background-color: rgba(255, 255, 255, 0.1);
        color: white;
        border: 1px solid rgba(255, 255, 255, 0.2);

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      &.submit-button {
        background-color: #f0f56c;
        color: black;
        border: none;

        &:hover {
          background-color: #e8ed4d;
        }

        &:disabled {
          opacity: 0.7;
          cursor: not-allowed;
        }
      }
    }
  }
}

.loading-spinner {
  color: #000000;
  font-size: 16px;
  text-align: center;
  margin-top: 10px;
}

.required {
  border: 1px solid red;
}

.modal-field label span {
  margin-left: 4px;
}

.create-radicado-button {
  /* Estilos base del botón */
  font-family: "Poppins", sans-serif;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  background-color: #f0f56c;
}

.create-radicado-button.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.create-radicado-button:not(.disabled) {
  background-color: #f0f56c;
  color: black;
}

.create-radicado-button:not(.disabled):hover {
  background-color: #0056b3;
}

// Estilos para los campos del formulario
.modal-field {
  margin-bottom: 15px;

  label {
    display: block;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    margin-bottom: 8px;
    font-weight: 500;
  }

  // Estilos para inputs y selects
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="date"],
  select {
    width: 100%;
    padding: 10px 12px;
    background-color: rgba(97, 95, 95, 0.952);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    color: white;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    transition: all 0.3s ease;

    &:focus {
      outline: none;
      border-color: rgba(255, 255, 255, 0.5);
      background-color: rgba(0, 0, 0, 0.8);
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.1);
    }

    &:hover {
      border-color: rgba(255, 255, 255, 0.3);
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    &.required {
      border-color: rgba(255, 0, 0, 0.5);
    }
  }

  // Estilos específicos para select
  select {
    appearance: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12'%3E%3Cpath fill='white' d='M6 8L1 3h10z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 12px center;
    padding-right: 36px;

    option {
      background-color: #1a1a1a;
      color: white;
      padding: 8px;
    }
  }

  // Estilos para checkbox
  input[type="checkbox"] {
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    transition: all 0.3s ease;

    &:checked {
      background-color: rgba(255, 255, 255, 0.9);
      border-color: transparent;

      &::after {
        content: "✓";
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: black;
        font-size: 14px;
      }
    }

    &:hover {
      border-color: rgba(255, 255, 255, 0.4);
    }
  }

  // Estilos para input file
  input[type="file"] {
    width: 100%;
    padding: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    border: 2px dashed rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      border-color: rgba(255, 255, 255, 0.4);
      background-color: rgba(0, 0, 0, 0.8);
    }

    &::file-selector-button {
      padding: 8px 16px;
      margin-right: 16px;
      background-color: rgba(255, 255, 255, 0.1);
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 4px;
      color: white;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }
}

// Estilo para campos predichos
.predicted {
  border-color: rgba(240, 245, 108, 0.5) !important;
  background-color: rgba(240, 245, 108, 0.1) !important;
}

// Estilos para las notificaciones toast
.Toastify__toast-container {
  font-family: "Poppins", sans-serif;
}

.Toastify__toast {
  background: rgba(0, 0, 0, 0.9);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  &--error {
    background: rgba(220, 38, 38, 0.9);
  }
}

.Toastify__toast-body {
  color: white;
  font-size: 14px;

  strong {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
  }

  ul {
    margin: 0;
    padding-left: 20px;

    li {
      margin: 4px 0;
      font-size: 13px;
      opacity: 0.9;
    }
  }
}

.Toastify__close-button {
  color: white;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

.Toastify__progress-bar {
  background: rgba(255, 255, 255, 0.2);
}
.non-editable-field {
  padding: 8px;
  background-color: #000000;
  border: 1px solid #000000;
  border-radius: 4px;
  color: #ffffff;
  min-height: 38px;
  display: flex;
  align-items: center;
}

input[type="date"] {
  &::-webkit-calendar-picker-indicator {
    filter: invert(1);
  }
  &::-webkit-datetime-edit-text,
  &::-webkit-datetime-edit-month-field,
  &::-webkit-datetime-edit-day-field,
  &::-webkit-datetime-edit-year-field {
    color: white;
  }
}

// Mejorar el botón de logout


.create-radicado-button {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
}

.Toastify__toast {
  background-color: #333333 !important;
  color: white !important;
}

.Toastify__toast-body {
  color: white !important;
}

.Toastify__close-button {
  color: white !important;
}

// Para mejorar la legibilidad de los elementos de la lista
.Toastify__toast ul li {
  color: #ffffff !important;
  margin-bottom: 4px;
}

.file-card {
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 16px 24px;
  margin-bottom: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-family: "Poppins", sans-serif;
  color: white;
  width: fit-content;
  height: 60px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
}

.toggle-bg {
  transition: background-color 0.3s ease;
}

input:checked+.toggle-bg {
  background-color: #4a5568;
  /* Dark mode background */
}

input:checked+.toggle-bg .toggle-dot {
  transform: translateX(100%);
  /* Move the dot to the right */
}

input:checked+.toggle-bg {
  background-color: #4a5568;
  /* Dark mode background */
}

.toggle-dot {
  transition: transform 0.3s ease;
}