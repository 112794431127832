@tailwind base;
@tailwind components;
@tailwind utilities;

/* 🌎 Global Styles */
@layer base {
  html,
  body {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
  }
    
  /* Light Mode Variables - Refinados */
  :root {
    --color-primary: #121212;
    --color-secondary: #ff6b00;
    --color-background: #f8f8f8;
    --color-text: #222222;
    --color-text-secondary: #555555;
    --color-accent: #ff6b00;
    --color-border: #e0e0e0;
    --color-card-bg: #ffffff;
    --color-card-hover: #f2f2f2;
    --nav-size: 0px;
    --transition-fast: 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    --transition-medium: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    --transition-slow: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.08);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    
    /* Document specific variables */
    --doc-bg-primary: #f8f8f8;
    --doc-bg-secondary: #ffffff;
    --doc-bg-tertiary: #f2f2f2;
    --doc-text-primary: #121212;
    --doc-text-secondary: #666666;
    --doc-border: #e0e0e0;
    --doc-hover: #f5f5f5;
    
    /* Folder & Document Cards */
    --folder-gradient: linear-gradient(135deg, rgba(233, 224, 44, 0.15) 0%, rgba(233, 224, 44, 0.05) 100%);
    --folder-border: rgba(233, 224, 44, 0.3);
    --folder-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    --document-gradient: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
    --document-border: rgba(255, 255, 255, 0.2);
    --document-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    
    /* Buttons & Interactive Elements */
    --button-primary-bg: #ff6b00;
    --button-primary-text: #121212;
    --button-secondary-bg: rgba(255, 255, 255, 0.1);
    --button-secondary-text: #121212;
    --button-hover-transform: scale(1.02);
    --button-active-transform: scale(0.98);
    
    /* Search Bar */
    --search-bg: rgba(255, 255, 255, 0.9);
    --search-text: #121212;
    --search-border: rgba(233, 224, 44, 0.3);
    --search-focus-shadow: 0 0 0 2px rgba(233, 224, 44, 0.3);
    
    /* Modal Styling */
    --modal-bg: #ffffff;
    --modal-text: #121212;
    --modal-border: rgba(0, 0, 0, 0.1);
    --modal-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }

  /* Dark Mode Variables - Refinados */
  .dark {
    --color-primary: #f8f8f8;
    --color-secondary: #ff6b00;
    --color-background: #121212;
    --color-text: #f0f0f0;
    --color-text-secondary: #b0b0b0;
    --color-accent: #ff6b00
    ;
    --color-border: #333333;
    --color-card-bg: #1c1c1c;
    --color-card-hover: #2c2c2c;
    --nav-size: 0px;
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.2);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.3);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.4);
    
    /* Document specific variables */
    --doc-bg-primary: #121212;
    --doc-bg-secondary: #1e1e1e;
    --doc-bg-tertiary: #252525;
    --doc-text-primary: #f0f0f0;
    --doc-text-secondary: #a0a0a0;
    --doc-border: #333333;
    --doc-hover: #2c2c2c;
    --doc-icon-brightness: 1.5;
    
    /* Folder & Document Cards */
    --folder-gradient: linear-gradient(135deg, rgba(240, 245, 108, 0.2) 0%, rgba(240, 245, 108, 0.05) 100%);
    --folder-border: rgba(240, 245, 108, 0.3);
    --folder-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    --document-gradient: linear-gradient(135deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%);
    --document-border: rgba(255, 255, 255, 0.15);
    --document-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    
    /* Buttons & Interactive Elements */
    --button-primary-bg: #f0f56c;
    --button-primary-text: #121212;
    --button-secondary-bg: rgba(255, 255, 255, 0.08);
    --button-secondary-text: #f0f0f0;
    --button-hover-transform: scale(1.02);
    --button-active-transform: scale(0.98);
    
    /* Search Bar */
    --search-bg: rgba(30, 30, 30, 0.8);
    --search-text: #f0f0f0;
    --search-border: rgba(240, 245, 108, 0.3);
    --search-focus-shadow: 0 0 0 2px rgba(240, 245, 108, 0.3);
    
    /* Modal Styling */
    --modal-bg: #1e1e1e;
    --modal-text: #f0f0f0;
    --modal-border: rgba(255, 255, 255, 0.1);
    --modal-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  }
}

/* Componentes del Archivo */
@layer components {
  /* Contenedor principal */
  .archive-container {
    @apply min-h-screen w-full;
    background-color: var(--doc-bg-primary);
    color: var(--doc-text-primary);
  }

  /* Títulos y textos */
  .archive-title {
    @apply text-2xl font-semibold mb-4;
    color: var(--doc-text-primary);
    font-family: 'Poppins', sans-serif;
  }

  .archive-subtitle {
    @apply text-xl font-medium mb-3;
    color: var(--doc-text-primary);
    font-family: 'Poppins', sans-serif;
  }

  .archive-text {
    @apply text-base;
    color: var(--doc-text-secondary);
    font-family: 'Poppins', sans-serif;
  }

  .order-folders-container {
    @apply flex justify-center items-center gap-4 p-4 border-b h-fit;
    background-color: var(--background-secondary);
    border-color: var(--border-color);
  }

  /* If you need to style the label and select within this container */
  .order-folders-container label {
    color: var(--text-primary);
  }

  .order-folders-container select {
    background-color: var(--background-primary);
    color: var(--text-primary);
    border-color: var(--border-color);
  }

  .order-folders-select-input option {
    background-color: var(--background-primary);
    color: var(--color-text-secondary);
    padding: 8px;
  }
  /* Contenedor para búsqueda y creación de carpetas */
  .archive-actions-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 1rem 0 1.5rem;
    padding: 0.5rem;
    border-radius: 12px;
  }

  /* Contenedor de búsqueda mejorado */
  .archive-search-container {
    position: relative;
    flex: 1;
    max-width: 70%;
  }

  .archive-search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--doc-text-secondary);
    font-size: 0.875rem;
    z-index: 1;
  }

  .archive-search-spinner {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--doc-text-secondary);
  }

  .archive-search-clear-button {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    color: var(--doc-text-secondary);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px;
    border-radius: 50%;
    transition: all var(--transition-fast);
  }

  .archive-search-clear-button:hover {
    background-color: var(--doc-hover);
    color: var(--doc-text-primary);
  }

  .archive-search-clear-button:active {
    transform: translateY(-50%) scale(0.95);
  }

  .archive-search-bar {
    width: 100%;
    padding: 0.75rem 2.5rem;
    border-radius: 8px;
    background-color: var(--search-bg);
    color: var(--search-text);
    border: 1px solid var(--doc-border);
    font-size: 0.875rem;
    transition: all var(--transition-fast);
  }

  .archive-search-bar:focus {
    outline: none;
    border-color: var(--color-accent);
    box-shadow: var(--search-focus-shadow);
  }

  /* Resultados de búsqueda desplegables */
  .search-results-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 0.5rem;
    background-color: var(--doc-bg-secondary);
    border-radius: 8px;
    border: 1px solid var(--doc-border);
    box-shadow: var(--shadow-md);
    max-height: 400px;
    overflow-y: auto;
    z-index: 10;
  }

  .search-results-section {
    padding: 1rem;
    border-bottom: 1px solid var(--doc-border);
  }

  .search-results-section:last-child {
    border-bottom: none;
  }

  .search-results-title {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--doc-text-primary);
    margin-bottom: 0.75rem;
  }

  .search-results-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .search-result-item {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 6px;
    cursor: pointer;
    transition: all var(--transition-fast);
  }

  .search-result-item:hover {
    background-color: var(--doc-hover);
  }

  .search-result-icon {
    width: 24px;
    height: 24px;
    margin-right: 0.75rem;
    object-fit: contain;
  }

  .search-result-name {
    flex: 1;
    font-size: 0.875rem;
    color: var(--doc-text-primary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .document-embedded-icon {
    color: var(--color-accent);
    margin-left: 0.5rem;
    font-size: 1rem;
  }

  .search-results-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0.5rem;
    margin-top: 0.5rem;
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    border: none;
    border-radius: 6px;
    font-size: 0.75rem;
    cursor: pointer;
    transition: all var(--transition-fast);
  }

  .search-results-toggle:hover {
    background-color: var(--doc-hover);
  }

  /* Botón de crear carpeta mejorado */
  .create-folder-button {
    display: flex;
    align-items: center;
    padding: 0.5rem 1.25rem;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid var(--color-accent);
    color: var(--color-accent);
    font-size: 0.875rem;
    font-weight: 500;
    cursor: pointer;
    transition: all var(--transition-fast);
    white-space: nowrap;
  }

  .create-folder-button:hover {
    background-color: rgba(255, 107, 0, 0.1);
    transform: translateY(-2px);
    box-shadow: var(--shadow-sm);
  }

  .create-folder-button:active {
    transform: translateY(0);
  }

  .create-folder-icon {
    margin-right: 0.5rem;
    font-size: 1rem;
  }

  /* Ajuste para modo oscuro */
  .dark .create-folder-button {
    border-color: var(--color-accent);
    color: var(--color-accent);
  }

  .dark .create-folder-button:hover {
    background-color: rgba(255, 107, 0, 0.2);
  }

  /* Tarjetas de carpetas */
  .employee-folder-card {
    @apply flex flex-col items-center justify-center p-4 mb-4 transition-all duration-200 cursor-pointer;
    background: var(--folder-gradient);
    border-radius: 16px;
    border: 1px solid var(--folder-border);
    box-shadow: var(--folder-shadow);
    backdrop-filter: blur(10px);
    
    &:hover {
      @apply transform -translate-y-1;
      box-shadow: var(--shadow-md);
    }
    
    &:active {
      @apply transform scale-[0.98];
    }
  }

  .employee-folder-logo {
    @apply w-12 h-12 mb-3 object-contain;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  }

  .employee-folder-logo-mini {
    @apply w-10 h-10 mr-3 object-contain;
    filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.1));
  }

  .employee-folder-icon {
    @apply text-3xl mb-2;
    color: var(--doc-text-primary);
  }

  .employee-folder-name {
    @apply text-sm font-medium text-center;
    color: var(--doc-text-primary);
    font-family: 'Poppins', sans-serif;
  }

  /* Tarjetas de documentos */
  .employee-document-card {
    @apply flex items-center px-4 py-3 mb-2 transition-all duration-200 cursor-pointer;
    background: var(--document-gradient);
    border-radius: 12px;
    border: 1px solid var(--document-border);
    box-shadow: var(--document-shadow);
    backdrop-filter: blur(8px);
    
    &:hover {
      @apply transform -translate-y-0.5;
      box-shadow: var(--shadow-md);
    }
    
    &:active {
      @apply transform scale-[0.99];
    }
  }

  .employee-document-search-card {
    @apply flex items-center px-3 py-2 mb-1 transition-all duration-200 cursor-pointer;
    background: var(--document-gradient);
    border-radius: 8px;
    border: 1px solid var(--document-border);
    box-shadow: var(--document-shadow);
    backdrop-filter: blur(8px);
    
    &:hover {
      @apply transform -translate-y-0.5;
      box-shadow: var(--shadow-sm);
    }
  }

  .search-highlight {
    background-color: var(--color-secondary);
    color: var(--color-text);
    padding: 0 2px;
    border-radius: 2px;
    font-weight: 500;
  }

  .employee-document-icon {
    @apply w-10 h-10 mr-3 object-contain;
    filter: brightness(var(--doc-icon-brightness, 1));
  }

  .employee-document-search-icon {
    @apply w-8 h-8 mr-2 object-contain;
    filter: brightness(var(--doc-icon-brightness, 1));
  }

  .employee-document-name {
    @apply text-sm font-normal;
    color: var(--doc-text-primary);
    font-family: 'Poppins', sans-serif;
    padding: 2px 4px;
    }
    
    .employee-document-name::-webkit-scrollbar {
      height: 4px;
    }
    
    .employee-document-name::-webkit-scrollbar-track {
      background: var(--background-primary);
    }
    
    .employee-document-name::-webkit-scrollbar-thumb {
      background: var(--border-color);
      border-radius: 4px;
    }
    
    .employee-document-name:hover {
      cursor: pointer;
  }

  /* Botones */
  .archive-button {
    @apply px-4 py-2 rounded-md font-medium transition-all duration-200 flex items-center gap-2;
    border-radius: 10px;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    height: 40px;
    justify-content: center;
    min-width: 120px;
  }

  .archive-button.primary {
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);
    border: none;
  }

  .archive-button.primary:hover {
    opacity: 0.9;
    transform: translateY(-2px);
  }

  .archive-button.secondary {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    border: none;
  }

  .archive-button.secondary:hover {
    background-color: var(--doc-hover);
    transform: translateY(-2px);
  }

  /* Estilo para el botón de asignar formato */
  .btn-secondary {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    border: none;
    border-radius: 10px;
    padding: 0.5rem 1rem;
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    font-weight: 500;
    height: 40px;
    min-width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all var(--transition-fast);
  }

  .btn-secondary:hover {
    background-color: var(--doc-hover);
    transform: translateY(-2px);
  }

  .btn-secondary:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(var(--color-accent-rgb), 0.3);
  }

  .employee-add-files-button {
    @apply fixed flex items-center justify-center text-center cursor-pointer transition-all duration-200;
    width: 300px;
    height: 60px;
    bottom: 80px;
    right: 70px;
    border-radius: 300px;
    background: var(--folder-gradient);
    border: 1px solid var(--folder-border);
    box-shadow: var(--folder-shadow);
    backdrop-filter: blur(10px);
    color: var(--doc-text-primary);
    font-size: 22px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    
    &:hover {
      @apply transform -translate-y-1;
      box-shadow: var(--shadow-md);
    }
    
    &:active {
      @apply transform scale-[0.98];
    }
  }

  /* Modales */
  .create-folder-modal {
    width: 500px;
    @apply flex items-center justify-center;
    background-color: var(--modal-bg);
    border-radius: 12px;
    border: 1px solid var(--modal-border);
    box-shadow: var(--modal-shadow);
    
    .modal-header {
      @apply font-medium;
      color: var(--doc-text-primary);
      font-family: 'Poppins', sans-serif;
      border-bottom: 1px solid var(--doc-border);
    }
    
    .modal-body {
      color: var(--doc-text-primary);
      background-color: var(--modal-bg);
      border-radius: 10px;
    }
    
    .modal-footer {
      border-top: 1px solid var(--doc-border);
      background-color: var(--modal-bg);
    }
  }

  .assign-format-modal {
    @apply flex items-center justify-center;
    background-color: var(--modal-bg);
    border-radius: 12px;
    border: 1px solid var(--modal-border);
    box-shadow: var(--modal-shadow);
  }

.document-master-modal {
  @apply flex items-center justify-center;
  border-radius: 12px;
  border: 1px solid var(--modal-border);
  box-shadow: var(--modal-shadow);

  .modal-content {
    height: 90vh;
    display: flex;
    flex-direction: column;
  }

  .document-name-input {
    @apply bg-transparent w-full;
    color: var(--text-primary);
    font-size: 1.25rem;
    font-weight: 500;
    padding: 0.25rem;
    width: 70%;

    &:hover,
    &:focus {
      background-color: var(--background-secondary);
      border-radius: 4px;
    }

    &:focus {
      box-shadow: 0 0 0 1px var(--color-accent);
      outline: none;
    }
  }

  .open-external-link {
    @apply cursor-pointer transition-colors;
    color: var(--doc-text-secondary);


    &:hover {
      color: var(--button-primary-bg);
    }
  }

  .loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  .modal-header {
    flex-shrink: 0;
  }

  .modal-title {
    width: 100%;
  }

  .main-spinner {
    color: var(--button-primary-bg) !important;
    width: 2rem !important;
    height: 2rem !important;
  }

  .modal-body {
    flex: 1;
    overflow: hidden;
    display: flex;

    .h-adjust {
      height: calc(100vh - 200px);
      overflow-y: auto;
    }

    .file-info {
      height: calc(100vh - 250px);
      overflow-y: auto;
      padding: 1rem;
      border-left: 1px solid var(--border-color);

      .format-values-container {
        overflow-y: auto;
      }
    }

    iframe {
      width: 100%;
      height: 90%;
      border: none;
    }
  }

  .modal-footer {
    flex-shrink: 0;
  }
}
  .select-folder-modal-item {
    @apply flex items-center justify-between w-full py-2 px-3 cursor-pointer transition-colors duration-150;
    border-bottom: 1px solid var(--doc-border);
    color: var(--doc-text-primary);
    font-size: 0.9em;
    
    &:hover {
      background-color: var(--doc-hover);
    }
  }

  /* Textos específicos */
  .employee-files-return-text {
    @apply text-right cursor-pointer underline mb-6 mr-5;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--doc-text-primary);
    font-family: 'Poppins', sans-serif;
    transition: color 0.2s ease;
    
    &:hover {
      color: var(--color-accent);
    }
  }

  .employee-files-subtitle {
    @apply text-left mb-5;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--doc-text-primary);
    font-family: 'Poppins', sans-serif;
  }

  /* Toggle de tema */
  .theme-toggle-container {
    @apply flex items-center;
  }

  .theme-toggle-icon {
    @apply text-lg;
    
    &.sun {
      color: #f0f56c;
    }
    
    &.moon {
      color: var(--doc-text-secondary);
    }
  }

  .theme-toggle-switch {
    @apply relative inline-flex items-center mx-3;
  }

  .theme-toggle-bg {
    @apply w-12 h-6 rounded-full shadow-inner transition-colors duration-200;
    background-color: var(--doc-bg-tertiary);
  }

  .theme-toggle-dot {
    @apply absolute w-5 h-5 rounded-full shadow transition-transform duration-200;
    background-color: var(--color-accent);
    transform: translateX(2px);
    
    .dark & {
      transform: translateX(calc(100% + 2px));
    }
  }

  /* Contenedor principal del encabezado de archivo */
  .archive-header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  /* Estilos para el encabezado de la carpeta seleccionada */
  .archive-folder-header {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid var(--doc-border);
  }

  .archive-folder-title-with-logo,
  .archive-folder-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .employee-folder-logo-mini {
    width: 40px;
    height: 40px;
    object-fit: contain;
    border-radius: 6px;
  }

  .archive-folder-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    color: var(--color-accent);
  }

  /* Ajustes para el título de la carpeta */
  .employee-files-subtitle {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--doc-text-primary);
  }

  .employee-files-no-content-message {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--doc-text-primary);
  }

  /* Navegación de carpetas */
  .archive-folder-navigation {
    display: flex;
    align-items: center;
    margin-right: 0.75rem;
  }

  .archive-pagination-button {
    color: var(--color-primary);
  }

  .archive-pagination-page-number{
    color: var(--color-primary);
  }

  .archive-back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    color: var(--doc-text-secondary);
    cursor: pointer;
    transition: all var(--transition-fast);
  }

  .archive-back-button:hover {
    background-color: var(--doc-bg-tertiary);
    color: var(--color-accent);
    transform: translateX(-2px);
  }

  .archive-back-button:active {
    transform: translateX(0);
  }

  /* Estilos para el mensaje de no resultados */
  .no-results-message {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
    padding: 1rem;
    border-radius: 8px;
    background-color: var(--doc-bg-secondary);
    color: var(--doc-text-primary);
    font-family: 'Poppins', sans-serif;
    font-size: 1em;
    font-weight: 400;
    text-align: center;
    border: 1px dashed var(--doc-border);
    max-width: 400px;
  }

  /* Estilos para iconos de documentos */
  .document-icon {
    width: 20px;
    height: 20px;
    color: var(--color-accent);
    margin-right: 0.5rem;
    flex-shrink: 0;
  }

  /* Estilos para contenedores de documentos relacionados */
  .assistant-related-documents-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-top: 0.75rem;
    padding: 0.75rem;
    background-color: var(--doc-bg-tertiary);
    border-radius: 8px;
    border: 1px solid var(--doc-border);
  }

  .assistant-related-document-container {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    border-radius: 6px;
    background-color: var(--doc-bg-secondary);
    cursor: pointer;
    transition: all var(--transition-fast);
  }

  .assistant-related-document-container:hover {
    background-color: var(--doc-hover);
    transform: translateY(-2px);
    box-shadow: var(--shadow-sm);
  }

  .assistant-related-document-container:active {
    transform: translateY(0);
  }

  /* Estilos para modales con documentos */
  .modal-document-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin: 1rem 0;
  }

  .modal-document-item {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    border-radius: 8px;
    background-color: var(--doc-bg-tertiary);
    cursor: pointer;
    transition: all var(--transition-fast);
  }

  .modal-document-item:hover {
    background-color: var(--doc-hover);
    transform: translateY(-2px);
    box-shadow: var(--shadow-sm);
  }

  .modal-document-item:active {
    transform: translateY(0);
  }

  .modal-document-icon {
    width: 24px;
    height: 24px;
    color: var(--color-accent);
    margin-right: 0.75rem;
  }

  .modal-document-name {
    flex: 1;
    font-size: 0.875rem;
    color: var(--doc-text-primary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  /* Estilos para los inputs de valores extraídos del formato */
  .format-values-container .form-control,
  .format-values-container input[type="text"] {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    border: 1px solid var(--doc-border);
    border-radius: 6px;
    padding: 0.5rem 0.75rem;
    font-size: 0.875rem;
    transition: all var(--transition-fast);
  }

  .format-values-container .form-control:focus,
  .format-values-container input[type="text"]:focus {
    border-color: var(--color-accent);
    box-shadow: 0 0 0 2px rgba(255, 107, 0, 0.1);
    outline: none;
  }

  .format-values-container .input-transparent {
    background-color: transparent;
    color: var(--doc-text-primary);
    border: none;
    padding: 0;
    height: auto;
    overflow: visible;
  }

  .format-values-container .format-field-button {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    border: 1px solid var(--doc-border);
    border-radius: 4px;
    width: 28px;
    height: 28px;
    padding: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition: all var(--transition-fast);
  }

  .format-values-container .format-field-button:hover {
    background-color: var(--doc-hover);
  }

  .format-values-container .format-field-button.delete-button i {
    color: #ff4d4f;
  }

  .format-values-container .format-field-button.add-button i {
    color: var(--color-accent);
  }

  /* Estilos específicos para los inputs transparentes en los valores de formato */
  .format-values-container input[type="text"][style*="background-color: transparent"],
  .format-values-container .form-control[style*="background-color: transparent"] {
    color: var(--doc-text-primary) !important;
    background-color: transparent !important;
    border: none !important;
    padding: 0 !important;
    height: auto !important;
    overflow: visible !important;
    display: inline-block !important;
    margin-right: 10px !important;
  }

  /* Asegurar que los inputs normales en el contenedor de valores también tengan el color correcto */
  .format-values-container input[type="text"]:not([style*="background-color: transparent"]),
  .format-values-container .form-control:not([style*="background-color: transparent"]) {
    background-color: var(--doc-bg-tertiary) !important;
    color: var(--doc-text-primary) !important;
    border: 1px solid var(--doc-border) !important;
    border-radius: 6px !important;
    padding: 0.5rem 0.75rem !important;
    font-size: 0.875rem !important;
    transition: all var(--transition-fast) !important;
    margin-top: 5px !important;
  }

  /* Estilos para los botones de acción en el contenedor de valores */
  .format-values-container .btn {
    background-color: var(--doc-bg-tertiary) !important;
    color: var(--doc-text-primary) !important;
    border: 1px solid var(--doc-border) !important;
    border-radius: 6px !important;
    transition: all var(--transition-fast) !important;
    padding: 0.375rem 0.75rem !important;
  }

  /* Estilos específicos para botones pequeños con iconos */
  .format-values-container .btn[style*="width: 28px"] {
    width: 28px !important;
    height: 28px !important;
    padding: 0 !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    border-radius: 4px !important;
  }

  .format-values-container .btn:hover {
    background-color: var(--doc-hover) !important;
    transform: translateY(-1px) !important;
    box-shadow: var(--shadow-sm) !important;
  }

  .format-values-container .btn:active {
    transform: translateY(0) !important;
    box-shadow: none !important;
  }

  /* Iconos específicos */
  .format-values-container .fa-trash {
    color: #ff4d4f !important;
    font-size: 12px !important;
  }

  .format-values-container .fa-plus {
    color: var(--doc-text-primary) !important;
    font-size: 12px !important;
  }

  /* Tooltips para los botones */
  .tooltip {
    background-color: var(--doc-bg-secondary) !important;
    color: var(--doc-text-primary) !important;
    border: 1px solid var(--doc-border) !important;
    border-radius: 4px !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 0.75rem !important;
    padding: 0.25rem 0.5rem !important;
    box-shadow: var(--shadow-sm) !important;
  }

  .tooltip .tooltip-arrow {
    border-top-color: var(--doc-border) !important;
  }

  /* Estilos para el menú contextual */
  .archive-context-menu {
    position: absolute;
    width: 200px;
    background-color: var(--doc-bg-secondary);
    z-index: 1000;
    box-shadow: var(--shadow-md);
    border-radius: 8px;
    border: 1px solid var(--doc-border);
    overflow: hidden;
  }

  .archive-context-menu-content {
    padding: 8px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
  }

  .archive-context-menu-item {
    padding: 8px 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: var(--doc-text-primary);
    border-radius: 4px;
    transition: all var(--transition-fast);
  }

  .archive-context-menu-item:hover {
    background-color: var(--doc-hover);
  }

  .archive-context-menu-item i {
    margin-right: 8px;
    color: var(--doc-text-primary);
    font-size: 14px;
  }

  .archive-context-menu-item.delete i {
    color: #ff4d4f;
  }

  .archive-context-menu-item.edit i {
    color: var(--color-accent);
  }

  .archive-context-menu-item.select i {
    color: #52c41a;
  }

  /* Estilos para el modal de selección de carpetas */
  .select-folder-modal .modal-content {
    background-color: var(--doc-bg-secondary) !important;
    color: var(--doc-text-primary) !important;
    border: 1px solid var(--doc-border) !important;
    border-radius: 12px !important;
    box-shadow: var(--shadow-lg) !important;
  }

  .select-folder-modal .modal-header {
    background-color: var(--doc-bg-secondary) !important;
    color: var(--doc-text-primary) !important;
    border-bottom: 1px solid var(--doc-border) !important;
    padding: 1rem 1.5rem !important;
  }

  .select-folder-modal .modal-title {
    color: var(--doc-text-primary) !important;
    font-weight: 600 !important;
    font-size: 1.25rem !important;
  }

  .select-folder-modal .modal-body {
    background-color: var(--doc-bg-secondary) !important;
    padding: 1.5rem !important;
    color: var(--doc-text-primary) !important;
  }

  .select-folder-modal .modal-footer {
    background-color: var(--doc-bg-secondary) !important;
    border-top: 1px solid var(--doc-border) !important;
    padding: 1rem 1.5rem !important;
  }

  .select-folder-modal .close {
    color: var(--doc-text-primary) !important;
    opacity: 0.7 !important;
    transition: opacity var(--transition-fast) !important;
  }

  .select-folder-modal .close:hover {
    opacity: 1 !important;
  }

  /* Estilos para los elementos de la lista de carpetas */
  .folder-list-container {
    max-height: 400px;
    overflow-y: auto;
    margin: 1rem 0;
    padding-right: 0.5rem;
  }

  .folder-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1rem;
    border-radius: 8px;
    background-color: var(--doc-bg-tertiary);
    margin-bottom: 0.5rem;
    cursor: pointer;
    transition: all var(--transition-fast);
    border: 1px solid transparent;
  }

  .folder-list-item:hover {
    background-color: var(--doc-hover);
    border-color: var(--doc-border);
  }

  .folder-list-item-content {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    flex: 1;
  }

  .folder-list-item-actions {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .folder-select-button {
    padding: 0.35rem 0.75rem;
    border-radius: 6px;
    font-size: 0.75rem;
    font-weight: 500;
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);
    border: none;
    transition: all var(--transition-fast);
    opacity: 0;
    transform: translateX(10px);
  }

  .folder-list-item:hover .folder-select-button {
    opacity: 1;
    transform: translateX(0);
  }

  .folder-select-button:hover {
    transform: var(--button-hover-transform);
    box-shadow: var(--shadow-sm);
  }

  .folder-select-button:active {
    transform: var(--button-active-transform);
  }

  .folder-list-item-icon {
    color: var(--color-accent);
    font-size: 1.25rem;
  }

  .folder-list-item-name {
    color: var(--doc-text-primary);
    font-size: 0.875rem;
    font-weight: 500;
  }

  .folder-list-item-arrow {
    color: var(--doc-text-secondary);
    transition: transform var(--transition-fast);
  }

  .folder-list-item:hover .folder-list-item-arrow {
    transform: translateX(2px);
    color: var(--color-accent);
  }

  /* Estilos para el modal de confirmación */
  .confirmation-modal .modal-content {
    background-color: var(--doc-bg-secondary) !important;
    color: var(--doc-text-primary) !important;
    border: 1px solid var(--doc-border) !important;
    border-radius: 12px !important;
    box-shadow: var(--shadow-lg) !important;
  }

  .confirmation-modal .modal-header {
    background-color: var(--doc-bg-secondary) !important;
    color: var(--doc-text-primary) !important;
    border-bottom: 1px solid var(--doc-border) !important;
    padding: 1rem 1.5rem !important;
  }

  .confirmation-modal .modal-body {
    background-color: var(--doc-bg-secondary) !important;
    padding: 1.5rem !important;
    color: var(--doc-text-primary) !important;
  }

  .confirmation-modal .modal-body h2 {
    color: var(--doc-text-primary) !important;
    font-size: 1rem !important;
    font-weight: 500 !important;
    margin: 0 !important;
  }

  .confirmation-modal .modal-footer {
    background-color: var(--doc-bg-secondary) !important;
    border-top: 1px solid var(--doc-border) !important;
    padding: 1rem 1.5rem !important;
  }

  /* Estilos para los botones en los modales */
  .modal-button {
    padding: 0.5rem 1rem !important;
    border-radius: 8px !important;
    font-weight: 500 !important;
    font-size: 0.875rem !important;
    transition: all var(--transition-fast) !important;
    border: none !important;
  }

  .modal-button.primary {
    background-color: var(--button-primary-bg) !important;
    color: var(--button-primary-text) !important;
  }

  .modal-button.secondary {
    background-color: var(--doc-bg-tertiary) !important;
    color: var(--doc-text-primary) !important;
    border: 1px solid var(--doc-border) !important;
  }

  .modal-button.danger {
    background-color: #ff4d4f !important;
    color: white !important;
  }

  .modal-button:hover {
    transform: var(--button-hover-transform);
    box-shadow: var(--shadow-sm);
  }

  .modal-button:active {
    transform: var(--button-active-transform);
  }

  .modal-button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
  }

  /* Cuando el spinner está visible, ajustar posición del botón de limpiar */
  .archive-search-spinner + .archive-search-clear-button {
    right: 40px;
  }

  /* Estilos para los botones de acción del archivo */
  .document-selection-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1.25rem;
    margin-bottom: 0.75rem;
    gap: 0.75rem;
  }

  .archive-action-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    transition: all var(--transition-fast);
    border: none;
    box-shadow: var(--shadow-sm);
  }

  .archive-action-button i {
    font-size: 0.875rem;
  }

  .archive-action-button.select-all {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    border: 1px solid var(--doc-border);
  }

  .archive-action-button.cancel {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    border: 1px solid var(--doc-border);
  }

  .archive-action-button.move {
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);
  }

  .archive-action-button:hover {
    transform: var(--button-hover-transform);
    box-shadow: var(--shadow-md);
  }

  .archive-action-button.select-all:hover,
  .archive-action-button.cancel:hover {
    background-color: var(--doc-hover);
  }

  .archive-action-button.move:hover {
    opacity: 0.9;
  }

  .archive-action-button:active {
    transform: var(--button-active-transform);
  }

  /* Estilos para modo oscuro */
  .dark .archive-action-button.select-all,
  .dark .archive-action-button.cancel {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
  }

  .dark .archive-action-button.move {
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);
  }
}