@tailwind base;
@tailwind components;
@tailwind utilities;

/* 🌎 Global Styles */
@layer base {
  html,
  body {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
  }
    
  /* Light Mode Default Variables - Refinados */
  :root {
    --color-primary: #121212;
    --color-secondary: #FFA500;
    --color-background: #f8f8f8;
    --color-text: #222222;
    --color-text-secondary: #555555;
    --color-accent: #FFA500;
    --color-border: #e0e0e0;
    --color-card-bg: #ffffff;
    --color-card-hover: #f2f2f2;
    --nav-size: 0px;
    --transition-fast: 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    --transition-medium: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    --transition-slow: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.08);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    --doc-bg-primary: #f8f8f8;
    --doc-bg-secondary: #ffffff;
    --doc-bg-tertiary: #f2f2f2;
    --doc-text-primary: #121212;
    --doc-text-secondary: #666666;
    --doc-border: #e0e0e0;
    --doc-hover: #f5f5f5;
  }

  /* Dark Mode Variables - Refinados */
  .dark {
    --color-primary: #f8f8f8;
    --color-secondary: #FFA500;
    --color-background: #0a0a0a;
    --color-text: #f0f0f0;
    --color-text-secondary: #b0b0b0;
    --color-accent: #FFA500;
    --color-border: #333333;
    --color-card-bg: #1c1c1c;
    --color-card-hover: #2c2c2c;
    --nav-size: 0px;
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.2);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.3);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.4);
    --doc-bg-primary: #1a1a1a;
    --doc-bg-secondary: #2a2a2a;
    --doc-bg-tertiary: #333333;
    --doc-text-primary: #f0f0f0;
    --doc-text-secondary: #a0a0a0;
    --doc-border: #333333;
    --doc-hover: #2c2c2c;
  }
}

/* Componentes reutilizables para Flows */
@layer components {
  /* Contenedor principal - ocupar toda la página */
  .document-container {
    @apply min-h-screen w-full;
    background-color: var(--doc-bg-primary);
  }

  .document-content {
    @apply p-6 h-full;
    min-height: calc(100vh - var(--nav-size));
  }

  /* Encabezado */
  .document-header-title {
    color: var(--doc-text-primary);
    @apply text-2xl font-medium text-left;
  }

  .document-header-description {
    color: var(--doc-text-secondary);
    @apply mt-1 text-sm;
  }

  /* Barra de búsqueda */
  .document-search-input {
    @apply w-[300px] pl-10 h-9 transition-colors;
    background-color: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
    color: var(--doc-text-primary);
    padding-left: 2.5rem !important;

    &:hover {
      background-color: var(--doc-bg-tertiary);
    }

    &:focus {
      @apply ring-1 ring-[#f0f56c]/20;
    }
  }

  /* Mejora de estilos para los botones */
  .document-button {
    @apply px-4 py-2 rounded-md font-medium transition-all duration-200 flex items-center gap-2;
    
    &.primary {
      background-color: var(--color-accent);
      color: var(--doc-bg-primary);
      
      &:hover {
        @apply opacity-90 transform scale-[1.02];
      }
      
      &:active {
        @apply transform scale-[0.98];
      }
    }
    
    &.secondary {
      background-color: var(--doc-bg-secondary);
      color: var(--doc-text-primary);
      border: 1px solid var(--doc-border);
      
      &:hover {
        background-color: var(--doc-bg-tertiary);
        @apply transform scale-[1.02];
      }
      
      &:active {
        @apply transform scale-[0.98];
      }
    }

    &.icon-button {
      @apply p-2 rounded-md;
      
      &:hover {
        background-color: var(--doc-bg-tertiary);
      }
    }

    &:disabled {
      @apply opacity-50 cursor-not-allowed;
      &:hover {
        @apply transform-none;
      }
    }
  }

  /* Contenedor de acciones */
  .document-actions {
    @apply flex items-center gap-3 flex-wrap;
  }

  .document-action-group {
    @apply flex items-center gap-2;
  }
  
  .document-action-group button {
    @apply ml-auto;
    border-radius: 6px;
  }

  /* Mejora de la barra de herramientas */
  .document-toolbar {
    @apply flex items-center justify-between mb-6 flex-wrap gap-4;
    background-color: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
    @apply p-4 rounded-lg;
  }

  /* Mejora del diseño de la tabla */
  .document-table-container {
    background-color: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
    @apply rounded-lg overflow-hidden;
  }

  .document-table {
    @apply w-full;
  }

  .document-table-header {
    background-color: var(--doc-bg-tertiary);
    @apply sticky top-0 z-10;

    th {
      @apply px-4 py-3 text-xs font-semibold tracking-wide text-left uppercase;
      color: var(--doc-text-secondary);
    }
  }

  .document-table-row {
    border-bottom: 1px solid var(--doc-border);
    @apply transition-colors;

    &:hover {
      background-color: var(--doc-bg-tertiary);
    }

    td {
      @apply px-4 py-3 text-sm;
      color: var(--doc-text-primary);
    }
  }

  /* Mejora de los filtros */
  .document-filters {
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6;
  }

  .document-filter-group {
    @apply flex flex-col gap-1;
    border-radius: 6px;
  }
  
  /* Estilos para los filtros desplegables */
  .modal-content {
    background-color: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
    transition: all var(--transition-medium);
  }
  
  .modal-label {
    color: var(--doc-text-primary);
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    @apply text-sm;
  }
  
  .modal-input {
    background-color: var(--doc-bg-tertiary) !important;
    color: var(--doc-text-primary) !important;
    border: 1px solid var(--doc-border) !important;
    border-radius: 6px;
    @apply py-2 px-3 text-sm transition-all duration-200;
    
    &:hover {
      border-color: var(--color-accent) !important;
    }
    
    &:focus {
      outline: none;
      border-color: var(--color-accent) !important;
      box-shadow: 0 0 0 2px rgba(240, 245, 108, 0.2);
    }
  }
  
  /* Filtros en FlowResults */
  .flow-filter-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.75rem 1.25rem;
    background-color: var(--doc-bg-secondary);
    border-bottom: 1px solid var(--doc-border);
  }
  
  .flow-filter-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: var(--doc-text-secondary);
  }
  
  .flow-filter-select {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    border: 1px solid var(--doc-border);
    border-radius: 6px;
    padding: 0.35rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    transition: border-color var(--transition-fast);
    cursor: pointer;
    outline: none;
  }
  
  .flow-filter-select:focus {
    border-color: var(--color-accent);
    box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.1);
  }

  /* Eliminar cualquier transformación en hover */
  .flow-filter-select:hover {
    border-color: var(--color-accent);
  }

  /* Ajustar también otros elementos que podrían tener el mismo problema */
  .flow-search:hover,
  .flow-filter-label:hover,
  .flow-filter-container:hover {
    /* Asegurar que no hay transformaciones en hover */
    transform: none !important;
  }

  /* Mejora de las tarjetas */
  .document-card {
    background-color: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
    @apply rounded-lg overflow-hidden transition-all duration-200;

    &:hover {
      @apply shadow-lg;
      transform: translateY(-2px);
    }
  }

  .document-card-header {
    @apply p-4 border-b;
    border-color: var(--doc-border);
  }

  .document-card-content {
    @apply p-4;
  }

  /* Mejora de la paginación */
  .document-pagination {
    @apply flex items-center justify-between mt-4 p-4;
    background-color: var(--doc-bg-secondary);
    border-top: 1px solid var(--doc-border);
  }

  .document-pagination-button {
    @apply px-3 py-1 rounded-md transition-colors;
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);

    &:hover:not(:disabled) {
      background-color: var(--color-accent);
      color: var(--doc-bg-primary);
    }

    &:disabled {
      @apply opacity-50 cursor-not-allowed;
    }
  }

  /* Tabla de flujos */
  .flow-table {
    @apply w-full;
    background-color: var(--doc-bg-secondary);
  }

  .flow-table-header {
    background-color: var(--doc-bg-secondary);
    border-bottom: 1px solid var(--doc-border);
    @apply text-xs font-semibold tracking-wide text-left uppercase;
    color: var(--doc-text-secondary);
  }

  .flow-table-row {
    @apply transition-colors cursor-pointer;
    border-bottom: 1px solid var(--doc-border);

    &:hover {
      background-color: var(--doc-bg-tertiary);
    }
  }

  .flow-table-cell {
    @apply px-4 py-3 text-sm;
    color: var(--doc-text-primary);
  }

  /* Badges */
  .flow-badge {
    @apply px-2.5 py-1 text-xs font-medium inline-flex items-center gap-1.5 transition-all duration-200;
    border-radius: 7px !important;

    &.badge-success {
      background-color: rgba(34, 197, 94, 0.1);
      color: rgb(34, 197, 94);
      @apply dark:bg-green-500/20 dark:text-green-400;
      border: 1px solid rgba(34, 197, 94, 0.2);
    }

    &.badge-pending {
      background-color: rgba(234, 88, 12, 0.1);
      color: rgb(234, 88, 12);
      @apply dark:bg-orange-500/20 dark:text-orange-400;
      border: 1px solid rgba(234, 88, 12, 0.2);
    }

    &.badge-warning {
      background-color: rgba(234, 179, 8, 0.1);
      color: rgb(234, 179, 8);
      @apply dark:bg-yellow-500/20 dark:text-yellow-400;
      border: 1px solid rgba(234, 179, 8, 0.2);
    }

    &.badge-info {
      background-color: rgba(59, 130, 246, 0.1);
      color: rgb(59, 130, 246);
      @apply dark:bg-blue-500/20 dark:text-blue-400;
      border: 1px solid rgba(59, 130, 246, 0.2);
    }

    &.badge-default {
      background-color: var(--doc-bg-tertiary);
      color: var(--doc-text-secondary);
      border: 1px solid var(--doc-border);
    }
  }

  /* Estilos para el skeleton loading */
  .document-skeleton {
    @apply w-full animate-pulse space-y-4;
  }

  .skeleton-row {
    @apply flex items-center gap-4 p-4 rounded-lg;
    background-color: var(--doc-bg-secondary);
  }

  .skeleton-cell {
    @apply h-4 rounded;
    background: linear-gradient(
      90deg,
      var(--doc-bg-tertiary) 0%,
      var(--doc-bg-secondary) 50%,
      var(--doc-bg-tertiary) 100%
    );
    background-size: 200% 100%;
    animation: shimmer 2s infinite;
  }

  .skeleton-cell.small {
    @apply w-16;
  }

  .skeleton-cell.medium {
    @apply w-32;
  }

  .skeleton-cell.large {
    @apply w-48;
  }

  @keyframes shimmer {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }

  /* Skeleton para la tabla */
  .skeleton-table {
    @apply w-full;
    background-color: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
    border-radius: 0.5rem;
    overflow: hidden;
  }

  .skeleton-header {
    @apply p-4 border-b;
    background-color: var(--doc-bg-secondary);
    border-color: var(--doc-border);
  }

  .skeleton-body {
    @apply divide-y;
    border-color: var(--doc-border);
  }

  /* Skeleton para cards */
  .skeleton-card {
    @apply p-4 rounded-lg;
    background-color: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
  }

  /* Skeleton para texto */
  .skeleton-text {
    @apply h-4 rounded;
    background: linear-gradient(
      90deg,
      var(--doc-bg-tertiary) 0%,
      var(--doc-bg-secondary) 50%,
      var(--doc-bg-tertiary) 100%
    );
    background-size: 200% 100%;
    animation: shimmer 2s infinite;
  }

  .skeleton-text.title {
    @apply w-3/4 h-6 mb-4;
  }

  .skeleton-text.subtitle {
    @apply w-1/2 mb-2;
  }

  /* Estilos para la tabla */
  .document-table {
    @apply w-full;
  }

  .document-table-row {
    @apply transition-colors cursor-pointer;
    color: var(--doc-text-primary);

    &:hover {
      background-color: var(--doc-hover);
    }
  }

  .document-table-cell {
    @apply px-4 py-3 text-sm;
    color: var(--doc-text-secondary);
  }

  .document-select {
    background-color: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
    @apply rounded-md text-sm;
    color: var(--doc-text-primary);

    &:focus {
      @apply ring-1 ring-[#f0f56c]/20;
      border-color: var(--doc-border);
    }
  }

  /* Estilos para los badges */
  .document-badge {
    @apply px-2 py-1 rounded-full text-xs font-medium;
  }

  /* Estilos para el visor de PDF */
  .pdf-viewer-container {
    background-color: var(--doc-bg-tertiary);
    @apply flex-1 relative flex flex-col overflow-hidden;
  }

  .pdf-placeholder {
    @apply flex items-center justify-center h-full;
    color: var(--doc-text-secondary);
  }

  /* Componentes flotantes */
  .floating-card {
    @apply rounded-lg p-4 cursor-pointer transition-all duration-200;
    background-color: var(--doc-bg-secondary);

    &:hover {
      background-color: var(--doc-bg-tertiary);
    }
  }

  .floating-card-group {
    background-color: var(--doc-bg-secondary);
    
    .floating-card:hover & {
      background-color: var(--doc-bg-tertiary);
    }
  }

  /* Estilos para flujos internos */
  .flow-card {
    background-color: var(--doc-bg-secondary);
    border-color: var(--doc-border);
    @apply border-0 shadow-lg;
  }

  /* Mejora de estilos para los detalles del flujo */
  .flow-detail-label {
    @apply text-sm font-medium mb-2 relative inline-flex items-center gap-2 transition-all duration-200;
    color: var(--doc-text-secondary);
    
    &::before {
      content: '';
      @apply w-1 h-1 rounded-full transition-all duration-200;
      background-color: var(--color-accent);
      opacity: 0;
      transform: scale(0);
    }

    &:hover {
      @apply pl-2;
      color: var(--doc-text-primary);
      
      &::before {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .flow-detail-value {
    @apply text-base py-2 px-4 rounded-md transition-all duration-200 relative overflow-hidden;
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    
    &::before {
      content: '';
      @apply absolute inset-0 opacity-0 transition-opacity duration-200;
      background: linear-gradient(
        45deg,
        transparent 0%,
        rgba(240, 245, 108, 0.05) 50%,
        transparent 100%
      );
    }

    &:hover {
      @apply transform scale-[1.01];
      
      &::before {
        @apply opacity-100;
      }
    }
  }

  /* Mejora de estilos para los encabezados */
  .flow-header {
    border-bottom: 1px solid var(--doc-border);
    @apply p-6;
    background-color: var(--doc-bg-secondary);
  }

  .flow-title {
    color: var(--doc-text-primary);
    @apply text-xl font-semibold mb-1;
    letter-spacing: -0.01em;
  }

  .flow-description {
    color: var(--doc-text-secondary);
    @apply text-sm leading-relaxed;
  }

  /* Mejora de estilos para los inputs de búsqueda */
  .flow-search {
    padding-left: 2.5rem !important;
    background-color: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
    color: var(--doc-text-primary);
    border-radius: 6px;
    height: 38px;
    transition: all var(--transition-fast);
  }

  .flow-search:focus {
    border-color: var(--color-accent);
    box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.1);
    outline: none;
  }

  .flow-search::placeholder {
    color: var(--doc-text-secondary);
    @apply opacity-70;
  }

  /* Mejora de estilos para los grupos de filtros */
  .filter-group {
    @apply space-y-2;
  }

  .filter-group-title {
    color: var(--doc-text-primary);
    @apply text-sm font-semibold mb-2;
    letter-spacing: 0.02em;
  }

  /* Estilos para las tarjetas de flujo */
  .flow-grid {
    @apply grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4;
  }

  .flow-item-card {
    @apply rounded-lg p-4 cursor-pointer transition-all duration-200;
    background-color: var(--doc-bg-secondary);

    &:hover {
      background-color: var(--doc-bg-tertiary);
    }
  }

  .flow-item-icon-container {
    @apply p-2 rounded-md transition-colors;
    background-color: var(--doc-bg-tertiary);

    .group:hover & {
      background-color: var(--doc-hover);
    }
  }

  .flow-item-icon {
    @apply h-5 w-5;
    color: var(--color-accent);
  }

  .flow-item-title {
    @apply font-medium;
    color: var(--doc-text-primary);
  }

  .flow-item-description {
    @apply text-sm mt-0.5;
    color: var(--doc-text-secondary);
  }

  .flow-item-stats {
    @apply flex items-center gap-2 text-sm;
    color: var(--doc-text-secondary);
  }

  /* Contenedor vacío para flujos */
  .flow-empty-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
    padding-bottom: 4rem;
    border-radius: 0.5rem;
    background-color: var(--doc-bg-secondary);
  }

  .flow-empty-icon {
    @apply h-16 w-16 mb-4;
    color: var(--doc-text-secondary);
  }

  .flow-empty-text {
    color: var(--doc-text-primary);
  }

  .flow-empty-subtext {
    color: var(--doc-text-secondary);
  }

  /* Estilos para el modal de flujos */
  .flow-modal-content {
    background-color: var(--doc-bg-primary);
    border-color: var(--doc-border);
    width: 90vw !important;
    max-width: 1200px !important;
    height: 90vh !important;
    padding: 0 !important;
    overflow: hidden !important;
    border-radius: 12px !important;
    box-shadow: var(--shadow-lg) !important;
  }

  .flow-modal-header {
    background-color: var(--doc-bg-secondary);
    border-bottom: 1px solid var(--doc-border);
    padding: 1rem !important;
    flex-shrink: 0 !important;
    height: auto !important;
  }

  .flow-modal-title {
    color: var(--doc-text-primary);
    font-size: 1.125rem !important;
    font-weight: 600 !important;
    margin: 0 !important;
  }

  .flow-details-panel {
    width: 35% !important;
    height: calc(90vh - 3.5rem) !important;
    overflow-y: auto !important;
    padding: 1rem !important;
    border-right: 1px solid var(--doc-border) !important;
    background-color: var(--doc-bg-secondary) !important;
  }

  .flow-pdf-panel {
    width: 65% !important;
    height: calc(90vh - 3.5rem) !important;
    overflow: hidden !important;
    background-color: var(--doc-bg-primary) !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .flow-pdf-panel .flex-1 {
    width: 100% !important;
    height: 100% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }

  .flow-pdf-panel iframe {
    width: 100% !important;
    height: 100% !important;
    border: none !important;
    display: block !important;
    object-fit: contain !important;
    background-color: var(--doc-bg-primary) !important;
  }

  .flow-detail-label {
    font-size: 0.75rem !important;
    font-weight: 600 !important;
    color: var(--doc-text-secondary) !important;
    margin-bottom: 0.25rem !important;
  }

  .flow-detail-value {
    font-size: 0.875rem !important;
    color: var(--doc-text-primary) !important;
    word-break: break-word !important;
    line-height: 1.4 !important;
  }

  .floating-card {
    background-color: var(--doc-bg-primary) !important;
    border-radius: 8px !important;
    padding: 0.75rem !important;
    margin-bottom: 0.75rem !important;
    box-shadow: var(--shadow-sm) !important;
    transition: all var(--transition-fast) !important;
    cursor: pointer !important;
    border: 1px solid var(--doc-border) !important;
  }

  .floating-card:hover {
    transform: translateY(-2px) !important;
    box-shadow: var(--shadow-md) !important;
    border-color: var(--color-accent) !important;
  }

  .flow-pdf-placeholder {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
    width: 100% !important;
    background-color: var(--doc-bg-secondary) !important;
  }

  .flow-empty-icon {
    width: 40px !important;
    height: 40px !important;
    color: var(--doc-text-secondary) !important;
    opacity: 0.7 !important;
  }

  .flow-empty-text {
    font-size: 1rem !important;
    font-weight: 500 !important;
    color: var(--doc-text-primary) !important;
    margin-top: 0.75rem !important;
  }

  .flow-empty-subtext {
    font-size: 0.75rem !important;
    color: var(--doc-text-secondary) !important;
    margin-top: 0.25rem !important;
  }

  /* Estilos para modales */
  .modal-content {
    background-color: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
    @apply rounded-lg shadow-lg;
  }

  .modal-header {
    background-color: var(--doc-bg-secondary);
    border-bottom: 1px solid var(--doc-border);
    @apply p-4;
  }

  .modal-title {
    color: var(--doc-text-primary);
    @apply text-lg font-semibold;
  }

  .modal-body {
    background-color: var(--doc-bg-secondary);
    @apply p-4;
  }

  .modal-footer {
    background-color: var(--doc-bg-secondary);
    border-top: 1px solid var(--doc-border);
    @apply p-4 flex justify-end gap-2;
  }

  /* Estilos para inputs en modales */
  .modal-input {
    background-color: var(--doc-bg-tertiary);
    border: 1px solid var(--doc-border);
    color: var(--doc-text-primary);
    @apply w-full px-3 py-2 rounded-md text-sm transition-all duration-200;

    &:hover {
      border-color: var(--color-accent);
    }

    &:focus {
      @apply ring-1 ring-[#f0f56c]/20;
      border-color: var(--color-accent);
      outline: none;
    }

    &::placeholder {
      color: var(--doc-text-secondary);
    }
  }

  /* Estilos para labels en modales */
  .modal-label {
    color: var(--doc-text-primary);
    @apply block text-sm font-medium mb-1;
  }

  /* Estilos para el modal de email */
  .email-modal {
    @apply max-w-lg w-full;
  }

  .email-form-group {
    @apply mb-4;
  }

  .email-textarea {
    @apply min-h-[100px] resize-y;
    background-color: var(--doc-bg-tertiary);
    border: 1px solid var(--doc-border);
    color: var(--doc-text-primary);
  }

  /* Estilos para el modal de WhatsApp */
  .whatsapp-modal {
    @apply max-w-2xl w-full;
  }

  .whatsapp-contacts-list {
    background-color: var(--doc-bg-tertiary);
    border: 1px solid var(--doc-border);
    @apply rounded-md p-2 max-h-[400px] overflow-y-auto;
  }

  .whatsapp-contact-item {
    @apply p-2 rounded-md flex items-center justify-between transition-colors;
    
    &:hover {
      background-color: var(--doc-hover);
    }
  }

  /* Estilos para el modal de confirmación */
  .confirm-modal {
    @apply max-w-md w-full;
  }

  .confirm-message {
    color: var(--doc-text-primary);
    @apply text-center mb-4;
  }

  /* Estilos para el modal de edición */
  .edit-modal {
    @apply max-w-md w-full;
  }

  .edit-form-group {
    @apply space-y-2;
  }

  /* Botones específicos para modales */
  .modal-button {
    @apply px-4 py-2 rounded-md font-medium transition-all duration-200;
    
    &.primary {
      background-color: var(--color-accent);
      color: var(--doc-bg-primary);
      
      &:hover {
        @apply opacity-90;
      }
    }
    
    &.secondary {
      background-color: var(--doc-bg-tertiary);
      color: var(--doc-text-primary);
      border: 1px solid var(--doc-border);
      
      &:hover {
        background-color: var(--doc-hover);
      }
    }

    &:disabled {
      @apply opacity-50 cursor-not-allowed;
    }
  }

  /* Estilos específicos para el modal de asignar formato */
  .assign-format-modal {
    background-color: var(--doc-bg-secondary);
    
    .modal-header {
      background-color: var(--doc-bg-secondary);
      border-bottom: 1px solid var(--doc-border);
    }
    
    .modal-title {
      color: var(--doc-text-primary);
      font-weight: 600;
    }
    
    .modal-body {
      background-color: var(--doc-bg-secondary);
      color: var(--doc-text-primary);
    }
    
    .modal-footer {
      background-color: var(--doc-bg-secondary);
      border-top: 1px solid var(--doc-border);
    }
    
    .file-preview {
      background-color: var(--doc-bg-tertiary);
      border: 1px solid var(--doc-border);
      border-radius: 0.5rem;
      overflow: hidden;
      padding: 0.5rem;
    }
    
    .modal-label {
      color: var(--doc-text-primary);
      font-weight: 500;
    }
    
    .modal-input {
      background-color: var(--doc-bg-tertiary) !important;
      color: var(--doc-text-primary) !important;
      border: 1px solid var(--doc-border) !important;
    }
    
    .modal-subtitle {
      color: var(--doc-text-primary);
      font-weight: 600;
      margin-bottom: 0.75rem;
    }
    
    .modal-checkbox {
      background-color: var(--color-text-secondary);
      accent-color: var(--color-primary);
      width: 1rem;
      height: 1rem;
    }
    
    .modal-divider {
      border-color: var(--doc-border);
      margin: 1rem 0;
    }
    
    .modal-error {
      color: #e53e3e;
      font-size: 0.875rem;
      margin-top: 0.5rem;
    }
    
    .location-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.5rem;
      border: 1px solid var(--doc-border);
      border-radius: 0.375rem;
      padding: 0.625rem;
      width: 100%;
      height: auto;
    }
    
    .location-icon {
      margin-right: 0.5rem;
    }
    
    .location-input {
      background-color: transparent !important;
      color: var(--doc-text-primary) !important;
      border-color: transparent !important;
      flex: 1;
    }
    
    .location-delete-btn {
      background-color: transparent !important;
      border: none !important;
      color: #e53e3e !important;
    }
    
    .location-separator {
      display: flex;
      justify-content: center;
      margin-bottom: 0.625rem;
      color: var(--doc-text-secondary);
    }
    
    .location-add-container {
      display: flex;
      justify-content: center;
      margin-bottom: 0.625rem;
    }
    
    .location-add-btn {
      background-color: transparent !important;
      border: 1px solid var(--doc-border) !important;
      color: var(--doc-text-primary) !important;
    }
    
    .format-values-container {
      overflow-y: auto;
      height: 400px;
      max-height: 400px;
      overflow-x: hidden;
      margin-top: 0.625rem;
      padding-right: 0.5rem;
    }
  }

  /* Estilos para los filtros en FlowResults */
  .flow-filters-container {
    background-color: var(--doc-bg-secondary);
    padding: 1rem;
    margin: 0 1rem 1rem 1rem;
  }

  .flow-filter-item {
    display: flex;
    flex-direction: column;
    border-radius: 6px;
    transition: all var(--transition-medium);
  }

  .flow-filter-item:hover {
    transform: translateY(-2px);
  }

  .flow-filter-label {
    color: var(--doc-text-primary);
    font-weight: 500;
    font-size: 0.875rem;
    margin-bottom: 0.25rem;
  }

  .flow-filter-select {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    border-radius: 6px;
    padding: 0.5rem;
    min-width: 180px;
    transition: all var(--transition-medium);
  }
  .flow-filter-select:focus {
    outline: none;
    border-color: var(--color-accent);
    box-shadow: 0 0 0 2px rgba(240, 245, 108, 0.2);
  }

  /* Estilos para modales de diálogo (Dialog) */
  .dialog-content {
    background-color: var(--doc-bg-secondary) !important;
    border: 1px solid var(--doc-border) !important;
    border-radius: 6px !important;
    color: var(--doc-text-primary) !important;
  }

  /* Estilos para el modal de envío de correo */
  .email-dialog {
    .dialog-title {
      color: var(--doc-text-primary) !important;
    }
    
    .dialog-description {
      color: var(--doc-text-secondary) !important;
    }
    
    label {
      color: var(--doc-text-secondary) !important;
    }
    
    input, textarea {
      background-color: var(--doc-bg-tertiary) !important;
      border: 1px solid var(--doc-border) !important;
      border-radius: 6px !important;
      color: var(--doc-text-primary) !important;
      
      &:focus {
        border-color: var(--color-accent) !important;
        box-shadow: 0 0 0 2px rgba(240, 245, 108, 0.2) !important;
      }
      
      &::placeholder {
        color: var(--doc-text-secondary) !important;
      }
    }
    
    button {
      &.cancel {
        background-color: var(--doc-bg-tertiary) !important;
        color: var(--doc-text-primary) !important;
        border-radius: 6px !important;
        border: 1px solid var(--doc-border) !important;
        
        &:hover {
          background-color: var(--doc-hover) !important;
        }
      }
      
      &.send {
        background-color: var(--color-accent) !important;
        color: var(--doc-bg-primary) !important;
        
        &:hover {
          opacity: 0.9 !important;
        }
      }
    }
  }

  /* Estilos para el modal de edición */
  .edit-dialog {
    input {
      background-color: var(--doc-bg-tertiary) !important;
      border: 1px solid var(--doc-border) !important;
      border-radius: 6px !important;
      color: var(--doc-text-primary) !important;
      
      &:focus {
        border-color: var(--color-accent) !important;
        box-shadow: 0 0 0 2px rgba(240, 245, 108, 0.2) !important;
      }
    }
    
    button {
      &.cancel {
        color: var(--doc-text-primary) !important;
        border: 1px solid var(--doc-border) !important;
        border-radius: 6px !important;

        &:hover {
          color: var(--color-accent) !important;
          background-color: var(--doc-bg-secondary) !important;
        }
      }
      
      &.save {
        background-color: var(--doc-bg-secondary) !important;
        color: var(--doc-text-primary) !important;
        border: 1px solid var(--doc-border) !important;
        
        &:hover {
          color: var(--color-accent) !important;
        }
      }
    }
  }

  /* Estilos para el modal de contactos de WhatsApp */
  .whatsapp-dialog {
    background-color: var(--doc-bg-secondary) !important;
    border: 1px solid var(--doc-border) !important;
    border-radius: 6px !important;
    max-width: 650px !important;
    width: 90vw !important;
    
    .dialog-title {
      color: var(--doc-text-primary) !important;
      font-size: 1.25rem !important;
      font-weight: 600 !important;
    }
    
    .dialog-description {
      color: var(--doc-text-secondary) !important;
      margin-top: 0.5rem !important;
    }
    
    .modal-body {
      max-height: 450px !important;
      overflow-y: auto !important;
      padding: 1rem !important;
      background-color: var(--doc-bg-secondary) !important;
      color: var(--doc-text-primary) !important;
    }
    
    .contact-container {
      margin-bottom: 1rem !important;
      border-radius: 8px !important;
      overflow: hidden !important;
      border: 1px solid var(--doc-border) !important;
    }
    
    .contact-row {
      cursor: pointer !important;
      padding: 0.75rem 1rem !important;
      display: flex !important;
      justify-content: space-between !important;
      align-items: center !important;
      background-color: var(--doc-bg-tertiary) !important;
      transition: all var(--transition-fast) !important;
      
      &:hover {
        background-color: var(--doc-hover) !important;
      }
    }
    
    .contact-details {
      padding: 1rem !important;
      background-color: var(--doc-bg-secondary) !important;
      border-top: 1px solid var(--doc-border) !important;
    }
    
    .contact-label {
      color: var(--doc-text-primary) !important;
      font-weight: 500 !important;
      margin-bottom: 0.5rem !important;
      font-size: 0.875rem !important;
    }
    
    .contact-field {
      margin-bottom: 1rem !important;
    }
    
    .additional-info-title {
      color: var(--doc-text-primary) !important;
      font-weight: 500 !important;
      margin: 1rem 0 0.5rem 0 !important;
      font-size: 0.875rem !important;
    }
    
    .additional-info-row {
      margin-bottom: 0.75rem !important;
      display: flex !important;
      gap: 0.75rem !important;
      align-items: center !important;
    }
    
    .no-contacts-message {
      text-align: center !important;
      padding: 2rem !important;
      color: var(--doc-text-secondary) !important;
      background-color: var(--doc-bg-tertiary) !important;
      border-radius: 8px !important;
      margin-bottom: 1rem !important;
    }
    
    input, select {
      background-color: var(--doc-bg-tertiary) !important;
      border: 1px solid var(--doc-border) !important;
      border-radius: 6px !important;  
      color: var(--doc-text-primary) !important;
      padding: 0.5rem 0.75rem !important;
      font-size: 0.875rem !important;
      width: 100% !important;
      
      &:focus {
        border-color: var(--color-accent) !important;
        outline: none !important;
        box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.2) !important;
      }
    }
    
    .add-info-button {
      background-color: transparent !important;
      color: var(--color-accent) !important;
      border: 1px dashed var(--color-accent) !important;
      border-radius: 8px !important;
      padding: 0.75rem 1rem !important;
      width: 100% !important;
      margin-top: 1rem !important;
      transition: all var(--transition-fast) !important;
      font-weight: 500 !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      gap: 0.5rem !important;
      
      &:hover {
        background-color: rgba(240, 245, 108, 0.1) !important;
        transform: translateY(-2px) !important;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05) !important;
      }
      
      &:active {
        transform: translateY(0) !important;
      }
      
      svg {
        width: 18px !important;
        height: 18px !important;
        stroke-width: 2px !important;
      }
    }
    
    .add-contact-button {
      background-color: transparent !important;
      color: var(--color-accent) !important;
      border: 1px dashed var(--color-accent) !important;
      border-radius: 8px !important;
      padding: 0.75rem 1rem !important;
      width: 100% !important;
      margin-top: 1rem !important;
      transition: all var(--transition-fast) !important;
      font-weight: 500 !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      gap: 0.5rem !important;
      
      &:hover {
        background-color: rgba(240, 245, 108, 0.1) !important;
        transform: translateY(-2px) !important;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05) !important;
      }
      
      &:active {
        transform: translateY(0) !important;
      }
      
      svg {
        width: 18px !important;
        height: 18px !important;
        stroke-width: 2px !important;
      }
    }
    
    .delete-button {
      background-color: transparent !important;
      color: #dc3545 !important;
      border: none !important;
      padding: 0.5rem !important;
      border-radius: 50% !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      
      &:hover {
        background-color: rgba(220, 53, 69, 0.1) !important;
      }
    }
    
    button {
      &.cancel {
        background-color: var(--doc-bg-tertiary) !important;
        color: var(--doc-text-primary) !important;
        border: 1px solid var(--doc-border) !important;
        border-radius: 6px !important;
        padding: 0.5rem 1rem !important;
        font-weight: 500 !important;
        
        &:hover {
          background-color: var(--doc-hover) !important;
        }
      }
      
      &.save {
        background-color: var(--color-accent) !important;
        color: white !important;
        border: none !important;
        border-radius: 6px !important;
        padding: 0.5rem 1rem !important;
        font-weight: 500 !important;
        
        &:hover {
          background-color: darken(#FFA500, 10%) !important;
          opacity: 0.9 !important;
        }
      }
    }
  }

  /* Estilos para el spinner centrado */
  .spinner-container {
    @apply flex justify-center items-center w-full;
    min-height: 200px;
  }

  /* Animación para el spinner */
  @keyframes spinner-rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid var(--doc-bg-tertiary);
    border-radius: 50%;
    border-top-color: var(--color-accent);
    animation: spinner-rotation 1s ease-in-out infinite;
  }

  /* Estilos para los botones de selección de documentos */
  .document-selection-actions {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
    gap: 0.5rem;
  }

  .document-selection-button {
    background-color: var(--doc-bg-tertiary) !important;
    color: var(--doc-text-primary) !important;
    border: 1px solid var(--doc-border) !important;
    border-radius: 8px !important;
    padding: 0.5rem 1rem !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    transition: all var(--transition-fast) !important;
  }

  .document-selection-button:hover {
    background-color: var(--doc-hover) !important;
    transform: translateY(-2px) !important;
    box-shadow: var(--shadow-sm) !important;
  }

  .document-selection-button:active {
    transform: translateY(0) !important;
  }

  /* Estilos para la sección de archivos */
  .archive-header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 1.5rem;
  }

  .archive-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
    width: 100%;
  }

  .archive-folder-info {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .archive-actions-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
  }

  .archive-search-wrapper {
    flex: 1;
    max-width: 500px;
    margin-right: 1rem;
  }

  .archive-search-container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .archive-search-icon {
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: var(--doc-text-secondary);
  }

  .archive-search-bar {
    width: 100%;
    height: 40px;
    padding: 0.5rem 0.75rem 0.5rem 2.5rem;
    border-radius: 8px;
    border: 1px solid var(--doc-border);
    background-color: var(--doc-bg-secondary);
    color: var(--doc-text-primary);
    font-size: 0.875rem;
    transition: all var(--transition-fast);
  }

  .archive-search-bar:focus {
    border-color: var(--color-accent);
    box-shadow: 0 0 0 2px rgba(255, 165, 0, 0.1);
    outline: none;
  }

  .create-folder-button {
    background-color: transparent;
    color: var(--color-accent);
    border: 1px dashed var(--color-accent);
    border-radius: 8px;
    padding: 0.75rem 1.25rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all var(--transition-fast);
    white-space: nowrap;
  }

  .create-folder-button:hover {
    background-color: rgba(255, 165, 0, 0.1);
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  }

  .create-folder-button:active {
    transform: translateY(0);
  }

  .folder-icon {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
    color: var(--color-accent);
  }

  /* Estilos para la cuadrícula de carpetas y documentos */
  .archive-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 1rem;
    margin-top: 1.5rem;
  }

  .archive-empty-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
    text-align: center;
    background-color: var(--doc-bg-secondary);
    border-radius: 12px;
    border: 1px dashed var(--doc-border);
    margin: 2rem 0;
  }

  .archive-empty-icon {
    color: var(--doc-text-secondary);
    margin-bottom: 1rem;
    opacity: 0.7;
  }

  .archive-empty-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--doc-text-primary);
    margin-bottom: 0.5rem;
  }

  .archive-empty-description {
    font-size: 0.875rem;
    color: var(--doc-text-secondary);
    margin-bottom: 1.5rem;
  }

  .archive-empty-actions {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: center;
  }

  .upload-document-button {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    border: 1px solid var(--doc-border);
    border-radius: 8px;
    padding: 0.75rem 1.25rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    cursor: pointer;
    transition: all var(--transition-fast);
  }

  .upload-document-button:hover {
    background-color: var(--doc-hover);
    transform: translateY(-2px);
    box-shadow: var(--shadow-sm);
  }

  .upload-document-button:active {
    transform: translateY(0);
  }

  /* Mejoras para los resultados de búsqueda */
  .search-results-container {
    background-color: var(--doc-bg-secondary);
    border-radius: 8px;
    border: 1px solid var(--doc-border);
    box-shadow: var(--shadow-md);
    margin-top: 0.5rem;
    overflow: hidden;
  }

  .search-results-section {
    padding: 1rem;
    border-bottom: 1px solid var(--doc-border);
  }

  .search-results-section:last-child {
    border-bottom: none;
  }

  .search-results-title {
    font-size: 0.875rem;
    font-weight: 600;
    color: var(--doc-text-primary);
    margin-bottom: 0.75rem;
  }

  .search-results-list {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  .search-results-button {
    background-color: var(--doc-bg-tertiary);
    color: var(--doc-text-primary);
    border: none;
    border-radius: 6px;
    padding: 0.5rem;
    font-size: 0.75rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.25rem;
    cursor: pointer;
    transition: all var(--transition-fast);
    margin-top: 0.5rem;
    width: 100%;
  }

  .search-results-button:hover {
    background-color: var(--doc-hover);
  }

  /* Estilos para el selector de dashboard */
  .dashboard-select-input {
    text-align: center !important;
    font-size: 1.25rem !important;
    height: 60px !important;
    border-radius: 8px !important;
    border: 1px solid var(--doc-border) !important;
    background-color: var(--doc-bg-secondary) !important;
    color: var(--doc-text-primary) !important;
    font-family: 'Poppins', sans-serif !important;
    font-weight: 500 !important;
    padding: 0.5rem 1rem !important;
    transition: all var(--transition-fast) !important;
    box-shadow: var(--shadow-sm) !important;
    cursor: pointer !important;
  }

  .dashboard-select-input:focus {
    border-color: var(--color-accent) !important;
    box-shadow: 0 0 0 3px rgba(var(--color-accent-rgb), 0.2) !important;
    outline: none !important;
  }

  .dashboard-select-input:hover {
    border-color: var(--color-accent) !important;
  }

  .dashboard-select-option {
    background-color: var(--doc-bg-secondary) !important;
    color: var(--doc-text-primary) !important;
    font-family: 'Poppins', sans-serif !important;
    font-size: 1rem !important;
    padding: 0.5rem !important;
  }

  /* Estilos para el spinner del dashboard */
  .dashboard-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100px;
    margin: 1rem 0;
  }

  .dashboard-spinner {
    color: var(--color-accent) !important;
    width: 2rem !important;
    height: 2rem !important;
  }

  /* Contenedor del iframe */
  .business-dashboard-looker-container {
    background-color: var(--doc-bg-secondary);
    border-radius: 12px;
    border: 1px solid var(--doc-border);
    overflow: hidden;
    box-shadow: var(--shadow-md);
    margin-top: 1rem;
  }
}