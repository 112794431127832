@tailwind base;
@tailwind components;
@tailwind utilities;

/* 🌎 Global Styles */
@layer base {
  html,
  body {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
  }
    
  /* Light Mode Default Variables - Refinados */
  :root {
    --color-primary: #121212;
    --color-secondary: #FFA500;
    --color-background: #f8f8f8;
    --color-text: #222222;
    --color-text-secondary: #555555;
    --color-accent: #FFA500;
    --color-border: #e0e0e0;
    --color-card-bg: #ffffff;
    --color-card-hover: #f2f2f2;
    --nav-size: 0px;
    --transition-fast: 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    --transition-medium: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    --transition-slow: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.08);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
  }

  /* Dark Mode Variables - Refinados */
  .dark {
    --color-primary: #f8f8f8;
    --color-secondary: #FFA500;
    --color-background: #0a0a0a;
    --color-text: #f0f0f0;
    --color-text-secondary: #b0b0b0;
    --color-accent: #FFA500;
    --color-border: #333333;
    --color-card-bg: #1c1c1c;
    --color-card-hover: #2c2c2c;
    --nav-size: 0px;
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.2);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.3);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.4);
  }
}

/* Componentes reutilizables para Flows */
@layer components {
 
  /* Sidebar Styles */
  .business-backoffice-sidebar {
    @apply h-screen fixed left-0 top-0 float-left flex flex-col justify-between;
    background-color: var(--color-background);
    border-right: 1px solid var(--color-border);
    box-shadow: var(--shadow-sm);
    transition: all var(--transition-medium);
    will-change: width, transform, box-shadow;
    z-index: 10;

    &:hover {
      box-shadow: var(--shadow-lg);
    }
  }

  /* Sidebar Button Containers */
  .business-backoffice-top-buttons-container {
    @apply p-6 mb-6 flex justify-center items-center;
    padding-bottom: 2.5rem;
    border-bottom: 1px solid var(--color-border);
    transition: all var(--transition-medium);
  }

  .business-backoffice-bottom-buttons-container {
    @apply border-t p-4 mt-auto;
    border-color: var(--color-border);
  }

  /* Sidebar Tab Buttons */
  .business-sidebar-tab-button {
    @apply h-8 flex items-center justify-around w-5/6 mx-auto rounded-xl cursor-pointer;
    transition: all var(--transition-fast);
    margin: 8px auto;
    overflow: hidden;

    &:hover {
      background-color: var(--color-secondary);
      transform: translateX(4px);
      box-shadow: var(--shadow-md);
    }
  }

  /* Active Tab Button */
  .business-sidebar-active-tab-button {
    @apply h-[50px] flex items-center justify-around w-3/5 mx-auto rounded-xl cursor-pointer;
    background-color: var(--color-secondary);
    color: var(--color-text);
    position: relative;
    box-shadow: var(--shadow-md);
    transition: all var(--transition-fast);

    &::before {
      content: "";
      position: absolute;
      left: -10px;
      top: 50%;
      transform: translateY(-50%);
      width: 4px;
      height: 100%;
      background-color: var(--color-secondary);
      border-radius: 0 4px 4px 0;
      transition: transform var(--transition-medium);
    }

    &:hover::before {
      transform: translateY(-50%) scaleY(1.1);
    }
  }

  /* Tab Icons and Text */
  .business-sidebar-tab-icon {
    @apply w-5 h-5;
    transition: margin-right var(--transition-fast), transform var(--transition-fast);
  }

  .sidebar-expanded .business-sidebar-tab-icon {
    margin-right: 10px;
  }

  .business-sidebar-tab-button:hover .business-sidebar-tab-icon,
  .business-sidebar-active-tab-button:hover .business-sidebar-tab-icon {
    transform: scale(0.9);
  }

  .business-sidebar-tab-title {
    @apply text-sm font-medium flex items-center h-8;
    color: var(--color-text);
    margin-left: 10px;
    opacity: 1;
    transition: opacity var(--transition-fast), transform var(--transition-fast);
  }

  /* Logout Button */
  .business-sidebar-logout-button {
    @apply flex items-center justify-center gap-2 w-4/5 mx-auto p-2 rounded-xl cursor-pointer;
    background-color: var(--color-card-bg);
    color: var(--color-text);
    transition: all var(--transition-medium);
    box-shadow: var(--shadow-sm);

    &:hover {
      background-color: var(--color-secondary);
      box-shadow: var(--shadow-md);
      transform: translateY(-2px);
    }
  }

  /* Theme Toggle Container */
  .theme-toggle-container {
    @apply flex items-center justify-center mt-4 px-4;
    
    .toggle-bg {
      background-color: var(--color-border);
      transition: background-color var(--transition-fast);
    }

    .toggle-dot {
      background-color: var(--color-card-bg);
      transition: all var(--transition-fast);
    }

    input:checked + .toggle-bg {
      background-color: var(--color-secondary);
    }

    input:checked + .toggle-bg .toggle-dot {
      transform: translateX(100%);
      background-color: var(--color-background);
    }

    .fas.fa-sun {
      color: var(--color-text);
    }

    .fas.fa-moon {
      color: var(--color-text);
    }
  }

  /* Contenedor de botones del sidebar */
  .business-sidebar-button-container {
    @apply relative;
    justify-content: center;
    padding-left: 0px;
    transition: all var(--transition-medium);
  }

  .business-sidebar-button-container-expanded {
    justify-content: flex-start;
    padding-left: 10px;
  }

  /* Perfil */
  .business-profile-image {
    width: 60px;
    height: 60px;
    margin: 0.2rem;
    border-radius: 50%;
    background-color: white;
    box-shadow: var(--shadow-md);
    transition: transform var(--transition-medium);

    &:hover {
      transform: scale(1.05);
    }
  }

  .business-profile-container {
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all var(--transition-medium);
  }

  /* Panel de vista */
  .business-backoffice-viewpanel {
    transition: width var(--transition-medium), left var(--transition-medium), padding var(--transition-medium);
    will-change: width, left, padding;
    background-color: var(--color-background);
  }

  .business-backoffice-viewpanel.assistant {
    padding-top: 0px;
  }

  .business-backoffice-viewpanel:not(.assistant) {
    padding-top: 20px;
  }

  /* Mensaje de verificación */
  .verification-message-container {
    @apply flex justify-center items-center h-full;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeIn var(--transition-slow) forwards;
  }

  .verification-message {
    @apply font-poppins text-xl;
    color: var(--color-text);
    text-shadow: 0 1px 2px rgba(0,0,0,0.1);
  }

  /* Animación de aparición */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  .business-logo {
    @apply h-[45px];
    opacity: 0.9;
    transition: all var(--transition-medium) ease-in-out;
    transform-origin: center;

    &.expanded {
      width: auto; /* Para mantener la proporción cuando está expandido */
      height: 45px;
      opacity: 1;
    }

    &:not(.expanded) {
      height: 45px;
    }

    &:hover {
      opacity: 1;
      transform: scale(1.05);
    }
  }

  /* Estilos para el botón de cambio de tema */
  .theme-toggle-button {
    @apply flex items-center justify-center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: var(--color-text);
    transition: all var(--transition-fast);
    
    &:hover {
      background-color: var(--color-card-hover);
      transform: scale(1.05);
    }
    
    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px var(--color-secondary);
    }
  }

  .theme-toggle-icon {
    width: 20px;
    height: 20px;
  }

  /* Corregir el color del texto para la barra lateral en modo oscuro */
  .dark .business-sidebar-tab-title {
    color: var(--color-text);
  }

  .dark .business-sidebar-tab-button .business-sidebar-tab-title {
    color: var(--color-text);
  }

  .dark .business-sidebar-logout-button .business-sidebar-tab-title {
    color: var(--color-text);
  }

  .dark .business-sidebar-tab-button:hover .business-sidebar-tab-title,
  .dark .business-sidebar-active-tab-button:hover .business-sidebar-tab-title,
  .dark .business-sidebar-logout-button:hover .business-sidebar-tab-title {
    color: #ffffff;
  }

  .dark .business-sidebar-active-tab-button .business-sidebar-tab-title {
    color: #ffffff;
  }

  /* Corregir el color de los iconos en modo oscuro */
  .dark .business-sidebar-tab-button .business-sidebar-tab-icon,
  .dark .business-sidebar-logout-button .business-sidebar-tab-icon {
    color: var(--color-text);
  }
  
  .dark .business-sidebar-active-tab-button .business-sidebar-tab-icon {
    color: #ffffff;
  }
}