@tailwind base;
@tailwind components;
@tailwind utilities;

/* 🌎 Global Styles */
@layer base {
  html,
  body {
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
  }
    
  /* Light Mode Variables - Refinados */
  :root {
    --color-primary: #121212;
    --color-secondary: #FFA500;
    --color-background: #f8f8f8;
    --color-text: #222222;
    --color-text-secondary: #555555;
    --color-accent: #FFA500;
    --color-border: #e0e0e0;
    --color-card-bg: #ffffff;
    --color-card-hover: #f2f2f2;
    --nav-size: 0px;
    --transition-fast: 0.2s cubic-bezier(0.25, 0.1, 0.25, 1);
    --transition-medium: 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    --transition-slow: 0.5s cubic-bezier(0.25, 0.1, 0.25, 1);
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.05);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.08);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.1);
    
    /* Document specific variables */
    --doc-bg-primary: #f8f8f8;
    --doc-bg-secondary: #ffffff;
    --doc-bg-tertiary: #f2f2f2;
    --doc-text-primary: #121212;
    --doc-text-secondary: #666666;
    --doc-border: #e0e0e0;
    --doc-hover: #f5f5f5;
    
    /* Folder & Document Cards */
    --folder-gradient: linear-gradient(135deg, rgba(233, 224, 44, 0.15) 0%, rgba(233, 224, 44, 0.05) 100%);
    --folder-border: rgba(233, 224, 44, 0.3);
    --folder-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    --document-gradient: linear-gradient(135deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.05) 100%);
    --document-border: rgba(255, 255, 255, 0.2);
    --document-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    
    /* Buttons & Interactive Elements */
    --button-primary-bg: #FFA500;
    --button-primary-text: #121212;
    --button-secondary-bg: rgba(255, 255, 255, 0.1);
    --button-secondary-text: #121212;
    --button-hover-transform: scale(1.02);
    --button-active-transform: scale(0.98);
    
    /* Search Bar */
    --search-bg: rgba(255, 255, 255, 0.9);
    --search-text: #121212;
    --search-border: rgba(233, 224, 44, 0.3);
    --search-focus-shadow: 0 0 0 2px rgba(233, 224, 44, 0.3);
    
    /* Modal Styling */
    --modal-bg: #ffffff;
    --modal-text: #121212;
    --modal-border: rgba(0, 0, 0, 0.1);
    --modal-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
    
    /* Form Elements */
    --form-bg: #ffffff;
    --form-input-bg: #f5f5f5;
    --form-input-text: #121212;
    --form-input-border: #e0e0e0;
    --form-input-focus-border: #FFA500;
    --form-input-focus-shadow: 0 0 0 2px rgba(233, 224, 44, 0.2);
    --form-label-text: #333333;
    --form-placeholder: #999999;
    --form-select-bg: #f5f5f5;
    --form-select-text: #121212;
    --form-select-option-bg: #ffffff;
    --form-select-option-hover: #f5f5f5;
    --form-select-option-selected: #FFA500;
    --form-multivalue-bg: #f0f0f0;
    --form-multivalue-text: #333333;
    --form-multivalue-remove-hover: #e0e0e0;
    
    /* Table Styles */
    --table-header-bg: #f5f5f5;
    --table-header-text: #121212;
    --table-row-bg: #ffffff;
    --table-row-hover: #f8f8f8;
    --table-border: #e0e0e0;
    --table-text: #121212;
  }

  /* Dark Mode Variables - Refinados */
  .dark {
    --color-primary: #f8f8f8;
    --color-secondary: #FFA500;
    --color-background: #121212;
    --color-text: #f0f0f0;
    --color-text-secondary: #b0b0b0;
    --color-accent: #f0f56c;
    --color-border: #333333;
    --color-card-bg: #1c1c1c;
    --color-card-hover: #2c2c2c;
    --nav-size: 0px;
    --shadow-sm: 0 1px 3px rgba(0, 0, 0, 0.2);
    --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.3);
    --shadow-lg: 0 10px 15px -3px rgba(0, 0, 0, 0.4);
    
    /* Document specific variables */
    --doc-bg-primary: #121212;
    --doc-bg-secondary: #1e1e1e;
    --doc-bg-tertiary: #252525;
    --doc-text-primary: #f0f0f0;
    --doc-text-secondary: #a0a0a0;
    --doc-border: #333333;
    --doc-hover: #2c2c2c;
    --doc-icon-brightness: 1.5;
    
    /* Folder & Document Cards */
    --folder-gradient: linear-gradient(135deg, rgba(240, 245, 108, 0.2) 0%, rgba(240, 245, 108, 0.05) 100%);
    --folder-border: rgba(240, 245, 108, 0.3);
    --folder-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    --document-gradient: linear-gradient(135deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.02) 100%);
    --document-border: rgba(255, 255, 255, 0.15);
    --document-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    
    /* Buttons & Interactive Elements */
    --button-primary-bg: #FFA500;
    --button-primary-text: #121212;
    --button-secondary-bg: rgba(255, 255, 255, 0.08);
    --button-secondary-text: #f0f0f0;
    --button-hover-transform: scale(1.02);
    --button-active-transform: scale(0.98);
    
    /* Search Bar */
    --search-bg: rgba(30, 30, 30, 0.8);
    --search-text: #f0f0f0;
    --search-border: rgba(240, 245, 108, 0.3);
    --search-focus-shadow: 0 0 0 2px rgba(240, 245, 108, 0.3);
    
    /* Modal Styling */
    --modal-bg: #1e1e1e;
    --modal-text: #f0f0f0;
    --modal-border: rgba(255, 255, 255, 0.1);
    --modal-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
    
    /* Form Elements */
    --form-bg: #1e1e1e;
    --form-input-bg: #333333;
    --form-input-text: #f0f0f0;
    --form-input-border: #444444;
    --form-input-focus-border: #FFA500;
    --form-input-focus-shadow: 0 0 0 2px rgba(240, 245, 108, 0.2);
    --form-label-text: #d0d0d0;
    --form-placeholder: #888888;
    --form-select-bg: #333333;
    --form-select-text: #f0f0f0;
    --form-select-option-bg: #1e1e1e;
    --form-select-option-hover: #444444;
    --form-select-option-selected: #555555;
    --form-multivalue-bg: #444444;
    --form-multivalue-text: #f0f0f0;
    --form-multivalue-remove-hover: #666666;
    
    /* Table Styles */
    --table-header-bg: #252525;
    --table-header-text: #f0f0f0;
    --table-row-bg: #1e1e1e;
    --table-row-hover: #2c2c2c;
    --table-border: #333333;
    --table-text: #f0f0f0;
  }
}

/* Componentes del Archivo */
@layer components {
  /* Contenedores */
  .employees-list-container, .users-list-container {
    @apply w-full h-full p-5 rounded-lg;
    background-color: var(--doc-bg-secondary);
    box-shadow: var(--shadow-md);
    border: 1px solid var(--doc-border);
    color: var(--doc-text-primary);
    transition: background-color var(--transition-medium),
                border-color var(--transition-medium),
                color var(--transition-medium),
                box-shadow var(--transition-medium);
  }

  /* Botones */
  .add-employee-button {
    @apply w-10 h-10 flex items-center justify-center rounded-lg cursor-pointer transition-all duration-200;
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);
    font-size: 24px;
    font-weight: 600;
    
    &:hover {
      transform: var(--button-hover-transform);
      box-shadow: var(--shadow-md);
    }
    
    &:active {
      transform: var(--button-active-transform);
    }
  }

  .add-employee-text {
    @apply text-xl font-medium ml-3;
    font-family: 'Poppins', sans-serif;
    color: var(--doc-text-primary);
    transition: color var(--transition-medium);
  }

  /* Tablas */
  .employees-table, .users-table {
    @apply w-full border-collapse;
    color: var(--doc-text-primary);
    transition: color var(--transition-medium);
    
    thead {
      background-color: var(--table-header-bg);
      transition: background-color var(--transition-medium);
      
      tr {
        border-bottom: 2px solid var(--table-border);
        transition: border-color var(--transition-medium);
      }
      
      th {
        @apply py-3 px-4 text-left font-medium;
        color: var(--table-header-text);
        transition: color var(--transition-medium);
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
      }
    }
    
    tbody {
      tr {
        border-bottom: 1px solid var(--table-border);
        background-color: var(--table-row-bg);
        transition: background-color var(--transition-medium),
                    border-color var(--transition-medium);
        
        &:hover {
          background-color: var(--table-row-hover);
        }
      }
      
      td {
        @apply py-3 px-4;
        color: var(--table-text);
        transition: color var(--transition-medium);
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
      }
    }
  }

  /* Resto de los estilos... */
  .create-new-employee-button {
    @apply w-64 h-10 rounded-lg font-semibold transition-all duration-200;
    background-color: var(--button-primary-bg);
    color: var(--button-primary-text);
    font-family: 'Poppins', sans-serif;
    
    &:hover {
      transform: var(--button-hover-transform);
      box-shadow: var(--shadow-md);
    }
    
    &:active {
      transform: var(--button-active-transform);
    }
    
    &:disabled {
      opacity: 0.6;
      cursor: not-allowed;
      transform: none;
    }
  }

  /* Formularios */
  .user-form-section {
    background-color: var(--doc-bg-secondary);
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    border: 1px solid var(--doc-border);
    box-shadow: var(--shadow-sm);
  }

  .section-title {
    color: var(--form-label-text) !important;
    font-size: 1.5rem !important;
    font-weight: 600 !important;
    margin-bottom: 0.5rem !important;
    font-family: 'Poppins', sans-serif !important;
  }

  /* Estilos para inputs de formulario */
  .new-employee-form-input {
    background-color: var(--form-input-bg) !important;
    color: var(--form-input-text) !important;
    border: 1px solid var(--form-input-border) !important;
    border-radius: 8px !important;
    padding: 0.5rem 0.75rem !important;
    font-size: 0.875rem !important;
    font-family: 'Poppins', sans-serif !important;
    height: 38px !important;
    width: 100% !important;
    transition: all var(--transition-fast) !important;
  }

  .new-employee-form-input:focus {
    border-color: var(--form-input-focus-border) !important;
    box-shadow: var(--form-input-focus-shadow) !important;
    outline: none !important;
  }

  .new-employee-form-input::placeholder {
    color: var(--form-placeholder) !important;
  }

  /* Estilos para labels de formulario */
  .form-label {
    color: var(--form-label-text) !important;
    font-size: 0.875rem !important;
    font-weight: 500 !important;
    margin-bottom: 0.5rem !important;
    font-family: 'Poppins', sans-serif !important;
  }

  /* Estilos para botones de acción */
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 2rem;
  }

  .action-button {
    padding: 0.5rem 1rem;
    border-radius: 8px;
    font-weight: 500;
    font-size: 0.875rem;
    transition: all var(--transition-fast);
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
    box-shadow: var(--shadow-sm);
  }

  .action-button.submit {
    background-color: var(--button-primary-bg) !important;
    color: var(--button-primary-text) !important;
    border: none !important;
  }

  .action-button.submit:hover {
    transform: var(--button-hover-transform);
    box-shadow: var(--shadow-md);
    opacity: 0.9;
  }

  .action-button.cancel {
    background-color: var(--doc-bg-tertiary) !important;
    color: var(--doc-text-primary) !important;
    border: 1px solid var(--doc-border) !important;
  }

  .action-button.cancel:hover {
    background-color: var(--doc-hover) !important;
    transform: var(--button-hover-transform);
    box-shadow: var(--shadow-sm);
  }

  .action-button:active {
    transform: var(--button-active-transform);
  }

  .action-button:disabled {
    opacity: 0.6 !important;
    cursor: not-allowed !important;
    transform: none !important;
    box-shadow: none !important;
  }

  /* Contenedor para el spinner dentro del botón */
  .button-spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  /* Estilos para el spinner */
  .button-spinner {
    color: var(--button-primary-text) !important;
  }

  .dark .button-spinner {
    color: var(--color-text) !important;
  }

  /* Estilos para el contenedor de acciones del formulario */
  .form-actions {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1.5rem;
  }

  /* Perfiles y elementos de usuario */
  .employee-files-profile-picture {
    @apply w-20 h-20 rounded-full mb-5;
    border: 2px solid var(--doc-border);
    box-shadow: var(--shadow-sm);
  }

  .employee-files-employee-name {
    @apply text-lg font-medium mb-5 text-left;
    font-family: 'Poppins', sans-serif;
    color: var(--doc-text-primary);
  }

  .employee-files-return-text {
    @apply text-right cursor-pointer underline mb-6 mr-5;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--doc-text-primary);
    font-family: 'Poppins', sans-serif;
    transition: color 0.2s ease;
    
    &:hover {
      color: var(--color-accent);
    }
  }

  .employee-files-subtitle {
    @apply text-left mb-5;
    font-size: 1.8rem;
    font-weight: 600;
    color: var(--doc-text-primary);
    font-family: 'Poppins', sans-serif;
  }

  /* Tarjetas de carpetas y documentos */
  .employee-folder-card {
    @apply w-full mb-5 p-3 cursor-pointer flex flex-col items-center justify-center rounded-2xl transition-all duration-200;
    border: 1px solid var(--folder-border);
    background: var(--folder-gradient);
    backdrop-filter: blur(22px);
    box-shadow: var(--folder-shadow);
    
    &:hover {
      transform: var(--button-hover-transform);
      box-shadow: var(--shadow-lg);
    }
  }

  .employee-document-card {
    @apply w-full mb-2.5 flex items-center justify-start cursor-pointer rounded-2xl transition-all duration-200;
    height: 60px;
    border: 1px solid var(--document-border);
    background: var(--document-gradient);
    backdrop-filter: blur(22px);
    box-shadow: var(--document-shadow);
    color: var(--doc-text-primary);
    
    &:hover {
      transform: var(--button-hover-transform);
      box-shadow: var(--shadow-md);
    }
  }

  .employee-document-search-card {
    @apply w-full mb-1.5 flex items-center justify-start cursor-pointer rounded transition-all duration-200;
    height: 40px;
    border: 1px solid var(--document-border);
    background: var(--document-gradient);
    backdrop-filter: blur(22px);
    color: var(--doc-text-primary);
    
    &:hover {
      transform: var(--button-hover-transform);
      background-color: var(--doc-hover);
    }
  }

  /* Iconos y logos */
  .employee-document-icon {
    @apply w-12 h-12;
    filter: brightness(var(--doc-icon-brightness, 1));
  }

  .employee-document-search-icon {
    @apply w-9 h-9;
    filter: brightness(var(--doc-icon-brightness, 1));
  }

  .employee-folder-logo {
    @apply w-12 h-12 mb-5;
    filter: brightness(var(--doc-icon-brightness, 1));
  }

  .employee-folder-logo-mini {
    @apply w-10 h-10 mb-5;
    filter: brightness(var(--doc-icon-brightness, 1));
  }

  .employee-folder-icon {
    @apply text-3xl;
    color: var(--doc-text-primary);
  }

  /* Textos */
  .employee-folder-name {
    @apply text-base font-medium text-center;
    font-family: 'Poppins', sans-serif;
    color: var(--doc-text-primary);
  }

  .employee-document-name {
    @apply text-base font-normal my-auto;
    font-family: 'Poppins', sans-serif;
    color: var(--doc-text-primary);
  }

  /* Botón de agregar archivos */
  .employee-add-files-button {
    @apply fixed bottom-20 right-16 flex items-center justify-center cursor-pointer text-center text-xl font-normal rounded-full transition-all duration-200;
    width: 288px;
    height: 60px;
    border: 1px solid var(--folder-border);
    background: var(--folder-gradient);
    backdrop-filter: blur(22px);
    color: var(--doc-text-primary);
    font-family: 'Poppins', sans-serif;
    box-shadow: var(--shadow-lg);
    
    &:hover {
      transform: var(--button-hover-transform);
    }
    
    &:active {
      transform: var(--button-active-transform);
    }
  }

  /* Barra de búsqueda */
  .archive-search-bar {
    @apply w-full p-2 rounded transition-all duration-200 ml-2;
    background-color: var(--search-bg);
    color: var(--search-text);
    border: 1px solid var(--search-border);
    font-family: 'Poppins', sans-serif;
    
    &:focus {
      outline: none;
      box-shadow: var(--search-focus-shadow);
    }
  }

  /* Modales */
  .create-folder-modal {
    @apply w-full max-w-lg flex items-center justify-center;
    background-color: var(--modal-bg);
    border-radius: 12px;
    border: 1px solid var(--modal-border);
    box-shadow: var(--modal-shadow);
    
    .modal-header {
      @apply font-medium;
      color: var(--doc-text-primary);
      font-family: 'Poppins', sans-serif;
      border-bottom: 1px solid var(--doc-border);
    }
    
    .modal-body {
      color: var(--doc-text-primary);
      background-color: var(--modal-bg);
    }
    
    .modal-footer {
      border-top: 1px solid var(--doc-border);
      background-color: var(--modal-bg);
    }
  }

  .assign-format-modal {
    @apply flex items-center justify-center;
    background-color: var(--modal-bg);
    border-radius: 12px;
    border: 1px solid var(--modal-border);
    box-shadow: var(--modal-shadow);
    
    .modal-header {
      background-color: var(--doc-bg-secondary);
      border-bottom: 1px solid var(--doc-border);
      color: var(--doc-text-primary);
    }
    
    .modal-title {
      color: var(--doc-text-primary);
      font-weight: 600;
      font-family: 'Poppins', sans-serif;
    }
    
    .modal-body {
      background-color: var(--doc-bg-secondary);
      color: var(--doc-text-primary);
    }
    
    .modal-footer {
      background-color: var(--doc-bg-secondary);
      border-top: 1px solid var(--doc-border);
    }
    
    .file-preview {
      background-color: var(--doc-bg-tertiary);
      border: 1px solid var(--doc-border);
      border-radius: 0.5rem;
      overflow: hidden;
      padding: 0.5rem;
    }
    
    .modal-label {
      color: var(--doc-text-primary);
      font-weight: 500;
      font-family: 'Poppins', sans-serif;
    }
    
    .modal-input {
      background-color: var(--doc-bg-tertiary) !important;
      color: var(--doc-text-primary) !important;
      border: 1px solid var(--doc-border) !important;
    }
    
    .modal-subtitle {
      color: var(--doc-text-primary);
      font-weight: 600;
      margin-bottom: 0.75rem;
      font-family: 'Poppins', sans-serif;
    }
    
    .modal-checkbox {
      background-color: var(--doc-bg-tertiary);
      border: 1px solid var(--doc-border);
    }
    
    .modal-divider {
      border-color: var(--doc-border);
      margin: 1rem 0;
    }
    
    .modal-error {
      color: #e53e3e;
      font-size: 0.875rem;
      margin-top: 0.5rem;
    }
    
    .location-item {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.5rem;
      border: 1px solid var(--doc-border);
      border-radius: 0.375rem;
      padding: 0.625rem;
      width: 100%;
      height: auto;
      background-color: var(--doc-bg-tertiary);
    }
    
    .location-icon {
      margin-right: 0.5rem;
      color: var(--doc-text-primary);
    }
    
    .location-input {
      background-color: transparent !important;
      color: var(--doc-text-primary) !important;
      border-color: transparent !important;
      flex: 1;
    }
    
    .location-delete-btn {
      background-color: transparent !important;
      border: none !important;
      color: #e53e3e !important;
    }
    
    .location-separator {
      display: flex;
      justify-content: center;
      margin-bottom: 0.625rem;
      color: var(--doc-text-secondary);
    }
    
    .location-add-container {
      display: flex;
      justify-content: center;
      margin-bottom: 0.625rem;
    }
    
    .location-add-btn {
      background-color: transparent !important;
      border: 1px solid var(--doc-border) !important;
      color: var(--doc-text-primary) !important;
    }
    
    .format-values-container {
      overflow-y: auto;
      height: 400px;
      max-height: 400px;
      overflow-x: hidden;
      margin-top: 0.625rem;
      padding-right: 0.5rem;
    }
  }

  /* Elementos de selección */
  .select-folder-modal-item {
    @apply flex items-center justify-between w-full py-2 px-3 cursor-pointer transition-colors duration-150;
    border-bottom: 1px solid var(--doc-border);
    color: var(--doc-text-primary);
    font-size: 0.9em;
    
    &:hover {
      background-color: var(--doc-hover);
    }
  }

  /* Estilos para React-Select */
  .react-select-container {
    .react-select__control {
      background-color: var(--form-input-bg);
      border-color: var(--form-input-border);
      color: var(--form-input-text);
      font-family: 'Poppins', sans-serif;
      min-height: 38px;
      
      &:hover {
        border-color: var(--form-input-focus-border);
      }
      
      &.react-select__control--is-focused {
        border-color: var(--form-input-focus-border);
        box-shadow: var(--form-input-focus-shadow);
      }
    }
    
    .react-select__menu {
      background-color: var(--form-select-bg);
      border: 1px solid var(--form-input-border);
      box-shadow: var(--shadow-md);
    }
    
    .react-select__option {
      background-color: var(--form-select-option-bg);
      color: var(--form-select-text);
      
      &.react-select__option--is-focused {
        background-color: var(--form-select-option-hover);
      }
      
      &.react-select__option--is-selected {
        background-color: var(--form-select-option-selected);
      }
    }
    
    .react-select__multi-value {
      background-color: var(--form-multivalue-bg);
      
      .react-select__multi-value__label {
        color: var(--form-multivalue-text);
      }
      
      .react-select__multi-value__remove {
        color: var(--form-multivalue-text);
        
        &:hover {
          background-color: var(--form-multivalue-remove-hover);
          color: var(--form-multivalue-text);
        }
      }
    }
    
    .react-select__single-value {
      color: var(--form-input-text);
    }
    
    .react-select__placeholder {
      color: var(--form-placeholder);
    }
  }

  /* Estilos para la visualización de permisos */
  .permissions-cell {
    @apply relative;
  }
  
  .permissions-list {
    @apply flex flex-col;
    max-height: 100px;
    overflow: hidden;
    transition: max-height var(--transition-medium);
    
    &.expanded {
      max-height: 500px;
    }
  }
  
  .permission-item {
    @apply py-1;
    color: var(--doc-text-primary);
    font-size: 0.9rem;
  }
  
  .view-more-btn {
    @apply mt-1 text-xs py-1 px-2 rounded;
    background-color: var(--button-secondary-bg);
    color: var(--doc-text-primary);
    transition: background-color var(--transition-fast);
    
    &:hover {
      background-color: var(--button-primary-bg);
      color: var(--button-primary-text);
    }
  }

  /* Botones específicos para modales */
  .modal-button {
    @apply px-4 py-2 rounded-md font-medium transition-all duration-200;
    font-family: 'Poppins', sans-serif;
    
    &.primary {
      background-color: var(--button-primary-bg);
      color: var(--button-primary-text);
      border: none;
      box-shadow: var(--shadow-sm);
      
      &:hover {
        @apply opacity-90;
        transform: var(--button-hover-transform);
        box-shadow: var(--shadow-md);
      }
      
      &:active {
        transform: var(--button-active-transform);
        box-shadow: var(--shadow-sm);
      }
    }
    
    &.secondary {
      background-color: var(--doc-bg-tertiary);
      color: var(--doc-text-primary);
      border: 1px solid var(--doc-border);
      
      &:hover {
        background-color: var(--doc-hover);
        transform: var(--button-hover-transform);
        box-shadow: var(--shadow-sm);
      }
      
      &:active {
        transform: var(--button-active-transform);
      }
    }

    &:disabled {
      @apply opacity-50 cursor-not-allowed;
      transform: none;
      box-shadow: none;
    }
  }


  /* Spinner en botón primario */
  .modal-button.primary .spinner-border,
  .modal-button.primary .spinner-grow,
  .action-button.submit .spinner-border,
  .action-button.submit .spinner-grow {
    color: var(--button-primary-text) !important;
  }

  /* Spinner en modo oscuro */
  .dark .spinner-border, 
  .dark .spinner-grow {
    color: var(--color-accent) !important;
  }

  /* Estilos para el contenedor de acciones del formulario */
  .form-actions {
    @apply flex justify-end mt-6 space-x-3;
  }

  /* Sección de formulario */
  .user-form-section {
    @apply p-4 rounded-lg mb-6;
    background-color: var(--doc-bg-secondary);
    border: 1px solid var(--doc-border);
  }

  /* Estilos para elementos de negocio */
  .business-viewpanel-tab-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px;
    text-align: left;
    font-family: "Poppins", sans-serif;
    color: var(--doc-text-primary);
    transition: color var(--transition-medium);
  }

  .business-tab {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--doc-border);
    cursor: pointer;
    color: var(--doc-text-primary);
    transition: color var(--transition-medium), border-color var(--transition-medium);
  }

  .active-business-tab {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid var(--color-accent);
    cursor: pointer;
    color: var(--doc-text-primary);
    transition: color var(--transition-medium), border-color var(--transition-medium);
  }

  /* Contenedor para el spinner principal */
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 200px;
  }

  /* Estilos para el spinner principal */
  .main-spinner {
    color: var(--button-primary-bg) !important;
    width: 2rem !important;
    height: 2rem !important;
  }

  /* Spinner en modo oscuro */
  .dark .main-spinner {
    color: var(--color-accent) !important;
  }
}